import React from 'react'
import AndroidDevelopment from './AndroidDevelopment/Androiddevelopment'
import Iosdevelopment from './IosDevelopment/iosdevelopment'
import Reactnative from './ReactNative/Reactnative'

const AppDevelopment = () => {
  return (
   <>
   <AndroidDevelopment/>
   <Iosdevelopment/>
   <Reactnative/>
   </>
  )
}

export default AppDevelopment