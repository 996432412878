import React, { useEffect, useState } from 'react';
import './Navbar2.css'; // Import the CSS file for styles
import Navbar from '../Navbar/Navbar';

function Navbar2() {
  const [showNavbar2, setShowNavbar2] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    // Handle show/hide Navbar on scroll
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setShowNavbar2(false); // Hide on scroll down
      } else {
        setShowNavbar2(true); // Show on scroll up
      }
      lastScrollY = window.scrollY;
    };

    // Handle responsiveness
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Add event listeners
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Navbar />
      <div className={`navbar2 ${showNavbar2 ? 'visible' : 'hidden'}`}>
        {/* Left Section: Contact Info */}
        <div className="contact-info">
          {isMobile ? (
            <>
              <span className="contact-item">
                <i className="fas fa-envelope"></i>
              </span>
              <span className="contact-item">
                <i className="fas fa-phone-alt"></i>
              </span>
            </>
          ) : (
            <>
              <span className="contact-item">
                <i className="fas fa-envelope"></i>
                <a href="mailto:example@example.com">webixgo@gmail.com</a>
              </span>
              <span className="contact-item">
                <i className="fas fa-phone-alt"></i>
                <a href="tel:+1234567890">+91734-7271629</a>
              </span>
            </>
          )}
        </div>

        {/* Right Section: Social Icons */}
        <div className="social-icons">
          <a href="https://www.facebook.com/profile.php?id=61571715299914" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href=" https://x.com/home " target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://www.instagram.com/webixgo/?hl=en" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://www.linkedin.com/company/106135130/admin/dashboard/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>
      </div>
    </>
  );
}

export default Navbar2;
