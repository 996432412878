import React from "react";
import "./Googleads.css";
import { FaChartBar, FaDatabase, FaGlobe, FaPalette } from "react-icons/fa";
import img1 from "../../../Assest/execution.webp";

const GoogleAds = () => {
  return (
    <>
      <div className="googleads-page">
        {/* Banner Section */}
        <section className="googleads-banner">
          <h1>Google Ads</h1>
          <p>Maximize Your Reach with Tailored Google Ads Campaigns</p>
        </section>

        {/* Text and Image Section */}
        <section className="googleads-text-image">
          <div className="googleads-text">
            <h2 style={{ color: "var(--primary-color)" }}>
              Engage and Convert Customers with Google Ads
            </h2>
            <p>
            Google Ads is one of the most powerful advertising platforms available for businesses looking to drive targeted traffic to their website. At Webixgo, we specialize in Google Ads management, helping businesses optimize their pay-per-click (PPC) campaigns to maximize ROI. As a leading Google Ads company, we craft custom campaigns tailored to your business goals, whether you're looking to drive traffic, generate leads, or increase sales.

Our team focuses on keyword research, bid strategy, and ad copy optimization to ensure your Google Ads campaigns reach the right audience at the right time. We also perform detailed analysis using Google Analytics and AdWords tools to track campaign performance and continuously refine strategies. With our Google Ads services, we ensure that your ads appear in the most relevant search results, boosting your visibility and driving measurable results. Whether you want to target specific keywords or geographic regions, we help you create a winning Google Ads strategy to meet your marketing objectives.
            </p>
            <p>
              Leverage Google Ads services to dominate your industry:
            </p>
            <ul>
              <li>Customized strategies aligned with your Google Ads goals</li>
              <li>Comprehensive analytics and performance tracking</li>
              <li>Seamless integration with your existing marketing platforms</li>
              <li>Expert team with decades of experience in paid search marketing</li>
            </ul>
          </div>
          <div className="googleads-image">
            <img src={img1} alt="Google Ads" />
          </div>
        </section>

        {/* Why Choose Section */}
        <section className="googleads-why-choose">
          <h2 className="googleads-whychoose-title">
            Why Your Business Needs Google Ads Services
          </h2>
          <div className="googleads-whychoose-grid">
            <div className="googleads-whychoose-item">
              <FaChartBar size={50} color="white" />
              <h3>Scalability</h3>
              <p>
                Scale your Google Ads campaigns as your business grows. Our strategies adapt to your changing needs.
              </p>
            </div>
            <div className="googleads-whychoose-item">
              <FaDatabase size={50} color="white" />
              <h3>Data-Driven</h3>
              <p>
                Gain valuable insights into your campaigns with detailed, data-driven analysis for better optimization.
              </p>
            </div>
            <div className="googleads-whychoose-item">
              <FaGlobe size={50} color="white" />
              <h3>Global Reach</h3>
              <p>
                Expand your audience with Google’s advanced targeting options and global ad capabilities.
              </p>
            </div>
            <div className="googleads-whychoose-item">
              <FaPalette size={50} color="white" />
              <h3>Brand Consistency</h3>
              <p>
                Maintain a consistent brand identity across all Google Ads campaigns for trust and recognition.
              </p>
            </div>
          </div>
        </section>

        {/* Google Ads Manager Section */}
        <section className="googleads-manager-accounts">
          <h2 className="googleads-manager-title">
            The Two Types of Google Ads Manager Accounts
          </h2>
          <div className="googleads-manager-grid">
            <div className="googleads-manager-item">
              <h3>Ad Manager for Small Businesses (FREE)</h3>
              <p>
                Google Ad Manager for small businesses is a free ad server ideal for startups or publishers with limited resources.
              </p>
              <ul>
                <li>Access to Google’s AdSense and third-party networks</li>
                <li>Mobile and video ad optimization</li>
                <li>Forecasting and targeting tools</li>
                <li>Customized reporting</li>
              </ul>
            </div>
            <div className="googleads-manager-item">
              <h3>Ad Manager 360 (PAID)</h3>
              <p>
                Google Ad Manager 360 is a premium service that offers advanced ad inventory management and revenue optimization.
              </p>
              <ul>
                <li>Direct support from Google</li>
                <li>First-party audience segmentation</li>
                <li>Advanced video ad features</li>
                <li>Enhanced reporting and data tools</li>
              </ul>
            </div>
          </div>
        </section>

        {/* Additional Google Ads Section */}
        <section className="googleads-additional-section">
          <h2>How Google Ads Boosts Your ROI</h2>
          <div className="googleads-additional-content">
            <p>
              Google Ads can significantly enhance your return on investment (ROI) by targeting your audience with precision. From keyword-based search campaigns to display ads, Google Ads empowers businesses to connect with potential customers effectively.
            </p>
            <p>
              <strong>Benefits of Google Ads:</strong>
            </p>
            <ul>
              <li>Reach millions of users worldwide</li>
              <li>Cost-effective pay-per-click model</li>
              <li>Customizable campaigns for any budget</li>
              <li>Actionable insights from performance data</li>
            </ul>
            <p>
              Let our Google Ads team create a tailored plan that meets your unique business goals.
            </p>
          </div>
        </section>
      </div>
    </>
  );
};

export default GoogleAds;
