// EmailMarketing.js
import React from 'react';
import './EmailMarketing.css';

const EmailMarketing = () => {
  return (
    <div>
      {/* Banner Section */}
      <section className="EmailMarketing-banner">
        <h1>What Is Email Marketing?</h1>
        <p>Build a Loyal Community Around Your Brand</p>
      </section>

      {/* Introduction Section */}
      <section className="EmailMarketing-section">
        <h2>Introduction</h2>
        <div className="EmailMarketing-content">
          <p>Email Marketing remains one of the most effective strategies for engaging customers, building relationships, and driving conversions. At Webixgo, we specialize in email marketing services in Amritsar that deliver personalized, targeted campaigns directly to your audience’s inbox. Our team helps businesses design and implement customized email marketing strategies that increase open rates, drive traffic, and generate more sales.

We focus on segmentation, personalization, and creating visually appealing emails that grab attention and encourage action. From welcome emails and newsletters to promotional campaigns and abandoned cart reminders, Webixgo’s email marketing services in Amritsar are tailored to meet your business needs. Our data-driven approach ensures that each campaign is optimized for performance, helping you achieve better results, nurture customer loyalty, and increase ROI.</p>
        </div>
      </section>

      {/* Email Marketing Services Section */}
      <section className="EmailMarketing-section">
        <h2>Email Marketing Services</h2>
        <div className="EmailMarketing-content">
          <p>Email marketing is a digital marketing strategy used by thousands of businesses worldwide to create brand recognition, drive sales and build client relationships. Leveraging email service providers (ESPs), companies send automated, personalized emails containing brand promotions, announcements, newsletters, and more.</p>
        </div>
      </section>

      {/* Importance of Email Marketing Section */}
      <section className="EmailMarketing-section">
        <h2>Why Email Marketing Is Important</h2>
        <div className="EmailMarketing-content">
          <p>Online email marketing is one of the best marketing methods for earning business leads and delivering a high return on investment (ROI). With personalized communication and analytics-driven campaigns, email marketing helps build customer loyalty, expand business reach, connect with different audiences, and achieve high engagement and conversion rates.</p>
        </div>
      </section>

      {/* Cards Section */}
      <section className="EmailMarketing-cards">
        <h2>Key Benefits of Email Marketing</h2>
        <div className="EmailMarketing-cardContainer">
          <div className="EmailMarketing-card">
            <div className="EmailMarketing-icon">📩</div>
            <h3>Build Customer Loyalty</h3>
            <p>Strengthen client relationships with regular email interactions, driving both engagement and sales.</p>
          </div>
          <div className="EmailMarketing-card">
            <div className="EmailMarketing-icon">🌍</div>
            <h3>Expand Your Reach</h3>
            <p>Reach your target audience effectively on any device with well-crafted email campaigns.</p>
          </div>
          <div className="EmailMarketing-card">
            <div className="EmailMarketing-icon">🎯</div>
            <h3>Personalized Communication</h3>
            <p>Deliver tailored messages and offers to different audience segments for maximum impact.</p>
          </div>
          <div className="EmailMarketing-card">
            <div className="EmailMarketing-icon">📊</div>
            <h3>Analytics-Driven Strategies</h3>
            <p>Use data insights to optimize your campaigns and achieve better results.</p>
          </div>
        </div>
      </section>

      {/* Additional Email Marketing Insights Section */}
      <section className="EmailMarketing-section">
        <h2>Additional Insights</h2>
        <div className="EmailMarketing-content">
          <p>With advanced tools and techniques, businesses can test email campaigns, track analytics, and optimize strategies to ensure maximum effectiveness. Email marketing remains a vital component of a successful digital marketing strategy.</p>
        </div>
      </section>
    </div>
  );
};

export default EmailMarketing;