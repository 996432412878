import React from 'react';
import './Ourclient.css';
import logo1 from '../../Assest/medicoselogo.png';
import logo3 from '../../Assest/NB logo.webp';
import logo4 from '../../Assest/pinaki.webp';
import logo5 from '../../Assest/thirstyBear.webp';
import logo6 from '../../Assest/Greene_King.webp';
import logo7 from '../../Assest/greene-king-pub-partners1.webp';
import logo8 from '../../Assest/medicoselogo.png';
import logo9 from '../../Assest/NB logo.webp';
import logo10 from '../../Assest/pinaki.webp';
import logo11 from '../../Assest/thirstyBear.webp';
import logo12 from '../../Assest/Greene_King.webp';

const OurClient = () => {
  const logos = [logo1, logo3, logo4, logo5, logo6, logo7, logo8, logo9, logo10, logo11, logo12];

  return (
    <>
      <h1 className="client-heading">Our Clients</h1>
      <div className="ourclient-container">
        <div className="ourclient">
          {/* Logos for continuous scrolling */}
          {logos.map((logo, index) => (
            <img key={index} src={logo} alt={`logo-${index}`} className="client-logo" />
          ))}
          {logos.map((logo, index) => (
            <img key={`copy-${index}`} src={logo} alt={`logo-${index}-copy`} className="client-logo" />
          ))}
        </div>
      </div>
    </>
  );
};

export default OurClient;
