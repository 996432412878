import React from "react";
import "./androidevelopment.css";

const AndroidDevelopment = () => {
  return (
    <div className="androiddevelopment-container">
      {/* Header Section */}
      <div className="androiddevelopment-banner">
        <h1>Why Android Development is Crucial for Your Business</h1>
        <p>
          Unlock New Opportunities and Expand Your Reach. Developing an Android
          application can empower your business by reaching a wide audience,
          enhancing user engagement, and driving growth.
        </p>
      </div>

      {/* Benefits Section */}
      <div className="androiddevelopment-benefits">
        <div className="androiddevelopment-box green-box">
          <h3>Benefits of Android Development:</h3>
          <ul>
            <li>Reach a vast user base</li>
            <li>Leverage customizable solutions</li>
            <li>Achieve faster market deployment</li>
            <li>Ensure seamless integration</li>
            <li>Maximize ROI with cost-effective solutions</li>
          </ul>
        </div>
        <div className="androiddevelopment-box orange-box">
          <h3>Challenges Without a Mobile App:</h3>
          <ul>
            <li>Missed mobile audience</li>
            <li>Limited customer engagement</li>
            <li>Lower brand visibility</li>
            <li>Lagging behind competitors</li>
          </ul>
        </div>
      </div>

      {/* Features Section */}
      <div className="androiddevelopment-features">
        <h2>Why Choose Our Android Development Services</h2>
        <p>
          At Webixgo, we specialize in Android Development, offering innovative
          and scalable solutions to build high-performance Android applications.
          As a top Android development company, we use the latest tools and
          technologies to deliver customized mobile applications tailored to
          your business needs.
        </p>
        <div className="androiddevelopment-grid">
          <div className="grid-item">
            <div className="icon custom-app-icon">
              <i className="fas fa-mobile-alt"></i>
            </div>
            <h3>Custom App Development</h3>
            <p>Tailor-made Android apps to fit your unique business needs.</p>
          </div>
          <div className="grid-item">
            <div className="icon ui-ux-icon">
              <i className="fas fa-paint-brush"></i>
            </div>
            <h3>UI/UX Design</h3>
            <p>
              Create intuitive and visually appealing designs for enhanced user
              experience.
            </p>
          </div>
          <div className="grid-item">
            <div className="icon performance-icon">
              <i className="fas fa-tachometer-alt"></i>
            </div>
            <h3>High Performance</h3>
            <p>
              Deliver fast, secure, and reliable applications for optimal
              performance.
            </p>
          </div>
          <div className="grid-item">
            <div className="icon integration-icon">
              <i className="fas fa-plug"></i>
            </div>
            <h3>Seamless Integration</h3>
            <p>
              Ensure compatibility with existing systems and platforms for a
              smooth experience.
            </p>
          </div>
          <div className="grid-item">
            <div className="icon maintenance-icon">
              <i className="fas fa-tools"></i>
            </div>
            <h3>Maintenance & Support</h3>
            <p>
              Provide ongoing support to keep your app running smoothly and
              efficiently.
            </p>
          </div>
          <div className="grid-item">
            <div className="icon scalability-icon">
              <i className="fas fa-expand-arrows-alt"></i>
            </div>
            <h3>Scalability</h3>
            <p>Build scalable apps to grow alongside your business.</p>
          </div>
        </div>
      </div>

      {/* Process Section */}
      <div className="androiddevelopment-process">
        <h2>Our Android Development Process</h2>
        <p>
          We follow a structured approach to deliver exceptional results. Here
          are the steps we take:
        </p>
        <div className="process-stages">
          <div className="stage">
            <h3 className="primary-color">Plan</h3>
            <p>
              Understand your requirements and create a comprehensive
              development plan.
            </p>
          </div>
          <div className="stage">
            <h3 className="primary-color">Design</h3>
            <p>
              Develop user-centric designs to enhance usability and appeal.
            </p>
          </div>
          <div className="stage">
            <h3 className="primary-color">Develop</h3>
            <p>
              Build robust and feature-rich applications using the latest
              technologies.
            </p>
          </div>
          <div className="stage">
            <h3 className="primary-color">Deploy</h3>
            <p>
              Launch your application with thorough testing and quality
              assurance.
            </p>
          </div>
        </div>
        <p className="measure-line">Plan → Design → Develop → Deploy</p>
      </div>
    </div>
  );
};

export default AndroidDevelopment;
