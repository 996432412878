import React from 'react'
import Ecommercedesign from './Ecommerce_design/Ecommercedesign'
import Ecommerceppc from './Ecommerce_ppc/Ecommerceppc'
import Ecommerceseo from './Ecommerce_seo/Ecommerceseo'

const Ecommerce = () => {
  return (
    <>
    <Ecommercedesign/>
    <Ecommerceppc/>
    <Ecommerceseo/>
    </>
  )
}

export default Ecommerce