import React from 'react'
import GoogleAds from './Google-Ads/Googleads'
import LeadGenerationServices from './LeadGenerationServices/LeadGenerationServices'
import FranchisePPCMarketing from './Franchise-PPC-Marketing/Franchise-ppc-marketing'

const PPCAdvertising = () => {
  return (
    <>
    <GoogleAds/>
    <LeadGenerationServices/>
    <FranchisePPCMarketing/>
    </>
  )
}

export default PPCAdvertising