import React from 'react'
import CustomDesign from './CustomDesign/CustomDesign'
import WordPressBanner from './WordprerssDesign/WordpressDesign'
import ReactWebsite from './ReactWebsite/ReactWebsite'

const WebDesign = () => {
  return (
    <>
    <CustomDesign/>
    <WordPressBanner/>
    <ReactWebsite/>
    </>
  )
}

export default WebDesign