// Layout.js
import React from "react";
import Navbar2 from "../Navbar2/Navbar2";
import Footer from "../Footer/Footer";


const Layout = ({ children }) => {
  return (
    <>
      <Navbar2 />
      <main style={{ minHeight: "80vh" }}>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
