import React from 'react';
import Craousal from '../Component/Craousal/Craousel';
import Abouthome from '../Component/Abouthome/Abouthome';
import DigitalMarketingProcess from '../Component/DigitalMarketingProcess/DigitalMarketingProcess';
import BusinessesTransformed from '../Component/BussinessTransformed/BussenisTransformed';
import ClientTestimonials from '../Component/Testimonial/ClientTestimonial';
import CounterSection from '../Component/Counter/CounterSection';
import Industryweserve from '../Component/Industryweserve/Industryweserve';
import OurClient from '../Component/OurClient/Ourclient';
import FAQ from '../Component/FAQ/Faqsection';

import Serviceshome from '../Component/Serviceshome/Serviceshome';


const Home = () => {
  return (
    <>
  
      <Craousal />
      
      <DigitalMarketingProcess/>
    <Serviceshome/>
      <Industryweserve/>
      
      <Abouthome />
      <OurClient/>
      <BusinessesTransformed/>
      <CounterSection/>
      <ClientTestimonials/>
      <FAQ/>

    </>
  );
};

export default Home;
