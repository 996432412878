import React from 'react';
import './About3.css';
import { FaBullhorn, FaSearch, FaMousePointer, FaGlobe, FaThumbsUp, FaStar, FaShoppingCart, FaAmazon } from 'react-icons/fa';

const About3 = () => {
  return (
    <div className="services-container">
      <h2>We’re a Results-Driven Digital Marketing Agency</h2>
      <p>
        At Webixgo, we take a strategy-first approach to show you real results. Our digital marketing team goes above and beyond to ensure our web marketing campaigns not only drum up new business for our clients but also support sustainable success.
      </p>
      <div className="services-grid">
        {/* Service Items */}
        <div className="service-item">
          <div className="service-icon-container">
            <FaBullhorn className="service-icon" />
          </div>
          <h3>Digital Marketing</h3>
          <p>
            Gain complete control of your digital real estate with holistic online marketing services tailored to your brand.
          </p>
        </div>
        <div className="service-item">
          <div className="service-icon-container">
            <FaSearch className="service-icon" />
          </div>
          <h3>Search Engine Optimization (SEO)</h3>
          <p>
            Get white-hat SEO solutions that improve your organic search rankings and increase brand recognition.
          </p>
        </div>
        <div className="service-item">
          <div className="service-icon-container">
            <FaMousePointer className="service-icon" />
          </div>
          <h3>Pay-Per-Click (PPC) Marketing</h3>
          <p>
            Position your brand in front of the right people at the right time with a targeted paid ad campaign.
          </p>
        </div>
        <div className="service-item">
          <div className="service-icon-container">
            <FaGlobe className="service-icon" />
          </div>
          <h3>Web Design and Development</h3>
          <p>
            Improve your website accessibility and attract lifetime clients to your business.
          </p>
        </div>
        <div className="service-item">
          <div className="service-icon-container">
            <FaThumbsUp className="service-icon" />
          </div>
          <h3>Social Media Marketing</h3>
          <p>
            Bring your brand closer to your target audience and gain better customer engagement with social media marketing.
          </p>
        </div>
        <div className="service-item">
          <div className="service-icon-container">
            <FaStar className="service-icon" />
          </div>
          <h3>Online Reputation Management</h3>
          <p>
            What does your reputation say about your brand? Build and maintain your desired brand image to attract more business.
          </p>
        </div>
        <div className="service-item">
          <div className="service-icon-container">
            <FaShoppingCart className="service-icon" />
          </div>
          <h3>eCommerce Marketing</h3>
          <p>
            Facilitate a smooth buying process for your customers and reduce operational costs with strategic eCommerce marketing solutions.
          </p>
        </div>
        <div className="service-item">
          <div className="service-icon-container">
            <FaAmazon className="service-icon" />
          </div>
          <h3>Amazon Marketing</h3>
          <p>
            We help you optimize your Amazon product listings and improve your pricing strategy to increase your Amazon sales.
          </p>
        </div>
      </div>
    </div>
  );
};

export default About3;
