import React from 'react'
import StrategyDevelopment from './StrategyDevelopment/StrategyDevelopment'
import FranchiseMarketing from './FranchiseMarketing/FranchiseMarketing'
import EnterpriseMarketing from './EnterpriseMarketing/EnterpriseMarketing'

const DigitalMarketing = () => {
  return (
   <>
   <StrategyDevelopment/>
   <FranchiseMarketing/>
   <EnterpriseMarketing/>
   </>
  )
}

export default DigitalMarketing