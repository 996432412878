import React, { useState, useEffect } from "react";
import "./CounterSection.css";

const Counter = ({ title, endValue, duration, icon }) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    let start = 0;
    const increment = Math.ceil(endValue / (duration / 50)); // Adjust increment rate
    const interval = setInterval(() => {
      start += increment;
      if (start >= endValue) {
        setValue(endValue); // Set to the exact end value to avoid overshooting
        clearInterval(interval);
      } else {
        setValue(start);
      }
    }, 50); // Update every 50ms for smooth animation

    return () => clearInterval(interval); // Cleanup on component unmount
  }, [endValue, duration]);

  return (
    <div className="counter-item">
      <div className="counter-icon">{icon}</div>
      <h2 className="counter-value">
        {value}
        <span className="plus">+</span>
      </h2>
      <p className="counter-title">{title}</p>
    </div>
  );
};

const CounterSection = () => {
  return (
    <section className="counter-section">
      <Counter
        title="Happy Clients"
        endValue={150}
        duration={2000}
        icon={<i className="fas fa-smile-beam"></i>} // Font Awesome Icon
      />
      <Counter
        title="Projects Completed"
        endValue={700}
        duration={2500}
        icon={<i className="fas fa-clipboard-check"></i>} // Font Awesome Icon
      />
      <Counter
        title="Employees"
        endValue={100}
        duration={1800}
        icon={<i className="fas fa-users"></i>} // Font Awesome Icon
      />
    </section>
  );
};

export default CounterSection;
