import React from "react";
import "./BussinesTransformed.css"; // Updated CSS file

const companies = [
  { name: "Excel Currencies", logo: require("../../Assest/1.webp") },
  { name: "All American Tax Sales", logo: require("../../Assest/2.webp") },
  { name: "Secrets of Tax Lien Investing", logo: require("../../Assest/3.webp") },

];

const BusinessesTransformed = () => {
  return (
    <div className="businesses-container">
      <h2 className="businesses-title">
        Helped Over 150+ Businesses to Transform, and Grow Sales
      </h2>
      <p className="businesses-description">
        We help Businesses to Innovate, grow, and evolve
      </p>
      <div className="logos-container">
        {companies.map((company, index) => (
          <div className="logo-card" key={index}>
            <img
              src={company.logo}
              alt={`${company.name} Logo`}
              className="company-logo"
              onError={(e) => (e.target.src = "/path-to-fallback-image.webp")}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BusinessesTransformed;
