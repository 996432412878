import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "./Blog.css"; // Ensure to add styles for the page
import img1 from "../../Assest/1.webp";
import img2 from "../../Assest/2.webp";
import img3 from "../../Assest/3.webp";
import img4 from "../../Assest/digital.webp";
import img5 from "../../Assest/execution.webp";
import img6 from "../../Assest/photography.webp";

const BlogPage = () => {
  const [filter, setFilter] = useState("ALL");
  const navigate = useNavigate(); // Initialize navigate hook

  const projects = [
    { id: 1, name: "SBM Marketplace", category: "React Project", description: "A marketplace built using modern React technologies.", image: img1, technologies: "Laravel | Vue | HTML5 | CSS3" },
    { id: 2, name: "Welcome PartyHub", category: "SEO Project", description: "An SEO-optimized platform for event planning.", image: img2, technologies: "Laravel | HTML5 | CSS3" },
    { id: 3, name: "Excel Currencies", category: "App Project", description: "A currency exchange app with robust backend.", image: img3, technologies: "Laravel | Cake PHP | HTML5 | CSS3" },
    { id: 4, name: "Wooden House", category: "Social Media Project", description: "A social media campaign for a housing project.", image: img4, technologies: "React | Node | Tailwind" },
    { id: 5, name: "Customer Service", category: "App Project", description: "An app for enhancing customer service experience.", image: img5, technologies: "Angular | Spring Boot | CSS" },
    { id: 6, name: "Photography Studio", category: "Social Media Project", description: "A social media presence for a photography studio.", image: img6, technologies: "Vue | Laravel | Bootstrap" },
  ];

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredProjects =
    filter === "ALL"
      ? projects
      : projects.filter((project) => project.category === filter);

  const handleReadMoreClick = () => {
    navigate("/contact-us"); // Navigate to the Contact Us page
  };

  return (
    <>


      <div className="blog-page">
        {/* Banner Section */}
        <div className="banner-section">
          <h1>Our Success Stories</h1>
          <p>
            We have helped clients across the globe transform their tech development and digital marketing marketplaces for the future. Here are a few specimens of our exemplary case studies coming from clients ranging from startups to established enterprises.
          </p>
        </div>

        {/* Filter Section */}
        <div className="filter-section">
        
          <label htmlFor="category">Search by:</label>
          <select id="category" value={filter} onChange={handleFilterChange}>
            <option value="ALL">ALL</option>
            <option value="React Project">React Project</option>
            <option value="SEO Project">SEO Project</option>
            <option value="Social Media Project">Social Media Project</option>
            <option value="App Project">App Project</option>
          </select>
        </div>

        {/* Recent Work Section */}
        <div className="recent-work">
          <h2>Our Recent Work</h2>
          <div className="projects-grid">
            {filteredProjects.map((project) => (
              <div className="project-card" key={project.id}>
                <img src={project.image} alt={project.name} className="project-image" />
                <div className="card-content">
                  <h3>{project.name}</h3>
                  <p>{project.description}</p>
                  <p>
                    <strong>Technologies:</strong> {project.technologies}
                  </p>
                  <button
                    className="read-more"
                    onClick={handleReadMoreClick} // Add click handler for navigation
                  >
                    Read More
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPage;
