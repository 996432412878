import React, { useState } from "react";
import emailjs from "emailjs-com";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";  // Import default styles
import "./ContactUs.css";
import img from "../../Assest/help.webp";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    company: "",
    website: "",
    email: "",
    phone: "",
    businessDetails: "",
    referral: "",
  });

  const [message, setMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handlePhoneChange = (phone) => {
    setFormData({ ...formData, phone });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        "service_jgf9i7f", // Replace with your EmailJS Service ID
        "template_r215nvb", // Replace with your EmailJS Template ID
        formData,
        "9MQVI6mkzUo_dTksJ" // Replace with your EmailJS User ID
      )
      .then(
        () => {
          setMessage("Your message has been sent successfully!");
          setFormData({
            firstName: "",
            lastName: "",
            company: "",
            website: "",
            email: "",
            phone: "",
            businessDetails: "",
            referral: "",
          });
        },
        () => {
          setMessage("There was an error sending your message. Please try again.");
        }
      );
  };

  return (
    <div className="contact-us-page">
      <div className="top-banner">
        <h1>Contact Us</h1>
        <p>Partner with us to scale your business and achieve real results.</p>
      </div>

      <div className="form-container">
        <div className="form-section">
          <h2>Experience Real Results</h2>
          <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-row">
              <input
                type="text"
                name="firstName"
                placeholder="First Name*"
                value={formData.firstName}
                onChange={handleChange}
                required
              />
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={formData.lastName}
                onChange={handleChange}
         
              />
            </div>
            <div className="form-row">
              <input
                type="text"
                name="company"
                placeholder="Company/Organization"
                value={formData.company}
                onChange={handleChange}
       
              />
              <input
                type="text"
                name="website"
                placeholder="Website"
                value={formData.website}
                onChange={handleChange}
              />
            </div>
            <div className="form-row">
              <input
                type="email"
                name="email"
                placeholder="Email Address*"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <PhoneInput
                country={"in"} // Default country code
                value={formData.phone}
                onChange={handlePhoneChange}
                inputProps={{
                  name: "phone",
                  required: true,
                  placeholder: "Phone Number*",
                }}
              />
            </div>
            <textarea
              name="businessDetails"
              placeholder="Tell us about your business"
              value={formData.businessDetails}
              onChange={handleChange}
            ></textarea>
            <textarea
              name="referral"
              placeholder="How did you hear about Webixgo?"
              value={formData.referral}
              onChange={handleChange}
            ></textarea>
            <button type="submit">Send My Free Proposal</button>
          </form>
          {message && <p className="contact-note">{message}</p>}
        </div>

        <div className="info-section">
          <h2>Ready to Dominate Your Market?</h2>
          <img src={img} className="small-image" alt="Help" />
          <ol>
            <li>
              <strong>Contact Us</strong>: Fill out our brief contact form, and
              we'll be in touch with you soon to learn more about your business.
            </li>
            <li>
              <strong>Launch Targeted Campaigns</strong>: Our digital marketing
              experts will work closely with you to implement data-driven
              strategies that drive business growth.
            </li>
            <li>
              <strong>Achieve Lasting Growth</strong>: Solidify your digital
              presence, expand your brand reach and see outstanding results
              month-over-month.
            </li>
          </ol>
          <div className="contact-details">
            <p>
              <strong>Headquarters:</strong> Amritsar, Punjab,143006
            </p>
            <p>
              <strong>Office Hours:</strong> Mon-Fri, 9:30am – 6:30pm CT
            </p>
            <p>
              <strong>Main Phone:</strong> +91-7347271629
            </p>
          </div>
        </div>
      </div>

      <div className="map-section">
        <h3>Our Location</h3>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3424.188706399837!2d74.86457767607286!3d31.63189438131671!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39196320b4c6f11d%3A0x61fb788b82878415!2sAmritsar%2C%20Punjab%20143006%2C%20India!5e0!3m2!1sen!2sin!4v1687288003002!5m2!1sen!2sin"
          width="100%"
          height="300"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          title="Our Location"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUs;
