import React, { useState, useEffect } from "react";
import "./Crousal.css";

// Import videos and loading image from assets
import video1 from "../../Assest/craousal1.mp4";
import video2 from "../../Assest/craousal2.mp4";
import video3 from "../../Assest/craousal3.mp4";
import loadingImage from "../../Assest/logo.webp"; // Replace with your image path

const Carousel = () => {
  const videos = [video1, video2, video3];
  const [currentVideo, setCurrentVideo] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  useEffect(() => {
    const videoElement = document.getElementById("carousel-video");

    const handleEnded = () => {
      setCurrentVideo((prev) => (prev + 1) % videos.length);
    };

    const handleCanPlay = () => {
      setIsLoading(false);
      setLoadingPercentage(0);
    };

    videoElement.addEventListener("ended", handleEnded);
    videoElement.addEventListener("canplay", handleCanPlay);

    return () => {
      videoElement.removeEventListener("ended", handleEnded);
      videoElement.removeEventListener("canplay", handleCanPlay);
    };
  }, [currentVideo, videos.length]);

  useEffect(() => {
    let interval;
    if (isLoading) {
      interval = setInterval(() => {
        setLoadingPercentage((prev) => {
          if (prev >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prev + 10;
        });
      }, 100);
    }

    return () => clearInterval(interval);
  }, [isLoading]);

  return (
    <>
      <div className="carousel-container">
        {/* Loader */}
        {isLoading && (
          <div className="loader">
            <img src={loadingImage} alt="Loading" className="loader-image" />
            <p>Loading... {loadingPercentage}%</p>
          </div>
        )}

        {/* Video Carousel */}
        {videos.length > 0 && (
          <div className={`carousel-video-wrapper ${isLoading ? "hidden" : ""}`}>
            <video
              id="carousel-video"
              className="carousel-video"
              autoPlay
              muted
              key={videos[currentVideo]}
              controls={false} // Disable native controls
              controlsList="nodownload nofullscreen" // Prevent download and fullscreen
              onLoadStart={() => setIsLoading(true)}
              onClick={(e) => e.preventDefault()} // Prevent fullscreen on click
              onContextMenu={(e) => e.preventDefault()} // Disable right-click
              playsInline // Ensures no fullscreen on iOS devices
              style={{ pointerEvents: "none" }} // Prevents native behavior
            >
              <source src={videos[currentVideo]} type="video/mp4" />
            </video>
            <div className="carousel-controls">
              <button
                className="carousel-button"
                onClick={() =>
                  setCurrentVideo(
                    (prev) => (prev - 1 + videos.length) % videos.length
                  )
                }
              >
                &#8249;
              </button>
              <button
                className="carousel-button"
                onClick={() =>
                  setCurrentVideo((prev) => (prev + 1) % videos.length)
                }
              >
                &#8250;
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Carousel;
