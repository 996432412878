import React from "react";
import "./ReactWebsite.css"; // Ensure this CSS contains React-specific styles
import {
  FaCode,
  FaRocket,
  FaReact,
  FaCogs,
  FaDatabase,
  FaClipboardCheck,
  FaLayerGroup,
} from "react-icons/fa";
import reactImage from "../../../Assest/brandconsistency.webp"; // Replace with the actual image path

const ReactWebsite = () => {
  return (
    <section className="ReactWebsite-container">
      {/* What Is React Section */}
      <div className="ReactWebsite-content-wrapper">
        <div className="ReactWebsite-content">
          <h1 className="ReactWebsite-title">What Is React?</h1>
          <p className="ReactWebsite-description">
            Build Modern Web Applications with the Most Popular Frontend Library
          </p>
          <p className="ReactWebsite-text">
          React JS Development is a powerful front-end framework that enables businesses to create fast, interactive, and dynamic web applications. At Webixgo, we offer expert React JS development services to help you build responsive, high-performance web applications. As a leading React JS development company, we specialize in developing scalable, single-page applications (SPAs) that deliver superior user experiences.

Our team of skilled React developers uses the latest industry practices to build applications that are fast, efficient, and easy to maintain. Whether you're looking to create a custom dashboard, a real-time chat application, or an e-commerce platform, our React JS development solutions ensure a seamless, interactive experience for your users. We also focus on optimizing your app for performance, ensuring it loads quickly and works smoothly across different devices. By leveraging React JS, Webixgo delivers high-quality, future-ready web applications tailored to your business needs.
          </p>
          <p className="ReactWebsite-text">
            React allows developers to:
          </p>
          <ul className="ReactWebsite-list">
            <li>
              <FaCode className="ReactWebsite-icon" /> Build reusable UI components.
            </li>
            <li>
              <FaRocket className="ReactWebsite-icon" /> Enhance performance with Virtual DOM.
            </li>
            <li>
              <FaReact className="ReactWebsite-icon" /> Seamlessly integrate with backend APIs.
            </li>
          </ul>
        </div>
        <div className="ReactWebsite-image-container">
          <img
            src={reactImage}
            alt="React illustration"
            className="ReactWebsite-image"
          />
        </div>
      </div>

      {/* React Web Development Services */}
      <div className="ReactServices-section">
        <h2 className="ReactServices-title">React Web Development Services</h2>
        <p className="ReactServices-subtitle">
          Build Scalable and Performant Applications with React Experts
        </p>
        <div className="ReactServices-grid">
          {/* Service Cards */}
          <div className="ReactService-card">
            <h3 className="ReactService-card-title">
              React Application Development
            </h3>
            <p className="ReactService-card-text">
              We build high-quality React applications optimized for performance and scalability.
            </p>
          </div>
          <div className="ReactService-card">
            <h3 className="ReactService-card-title">Custom React Components</h3>
            <p className="ReactService-card-text">
              Develop reusable, efficient components tailored to your business needs.
            </p>
          </div>
          <div className="ReactService-card">
            <h3 className="ReactService-card-title">MERN Stack Development</h3>
            <p className="ReactService-card-text">
              Build full-stack applications with MongoDB, Express, React, and Node.js.
            </p>
          </div>
          <div className="ReactService-card">
            <h3 className="ReactService-card-title">State Management</h3>
            <p className="ReactService-card-text">
              Streamline app logic with Redux, Context API, or MobX for state management.
            </p>
          </div>
          <div className="ReactService-card">
            <h3 className="ReactService-card-title">
              Progressive Web Applications (PWAs)
            </h3>
            <p className="ReactService-card-text">
              Develop fast, reliable, and engaging PWAs powered by React.
            </p>
          </div>
          <div className="ReactService-card">
            <h3 className="ReactService-card-title">Performance Optimization</h3>
            <p className="ReactService-card-text">
              Optimize your React application for lightning-fast performance.
            </p>
          </div>
        </div>
      </div>

      {/* Why Hire React Experts */}
      <section className="WhyHireReact-container">
        <div className="WhyHireReact-content">
          <h2 className="WhyHireReact-title">Why Hire a React Web Development Company</h2>
          <p className="WhyHireReact-subtitle">
            Leverage React to Build High-Quality Applications with Expert Developers
          </p>
          <div className="WhyHireReact-grid">
            <div className="WhyHireReact-card">
              <FaRocket className="WhyHireReact-icon" />
              <h3 className="WhyHireReact-card-title">Maximum Efficiency</h3>
              <p className="WhyHireReact-card-text">
                React experts can rapidly develop scalable applications and deploy them seamlessly.
              </p>
            </div>
            <div className="WhyHireReact-card">
              <FaCogs className="WhyHireReact-icon" />
              <h3 className="WhyHireReact-card-title">Custom Development</h3>
              <p className="WhyHireReact-card-text">
                Tailor your React application to meet unique business goals with a custom approach.
              </p>
            </div>
            <div className="WhyHireReact-card">
              <FaDatabase className="WhyHireReact-icon" />
              <h3 className="WhyHireReact-card-title">End-to-End Solutions</h3>
              <p className="WhyHireReact-card-text">
                Benefit from full-stack React development, from frontend to backend integration.
              </p>
            </div>
            <div className="WhyHireReact-card">
              <FaLayerGroup className="WhyHireReact-icon" />
              <h3 className="WhyHireReact-card-title">Multi-Layer Support</h3>
              <p className="WhyHireReact-card-text">
                Ensure robust foundations with SEO-friendly, high-performing, and responsive designs.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Additional React/MERN Section */}
      <section className="ReactAdvanced-section">
        <h2 className="ReactAdvanced-title">Advanced MERN Solutions</h2>
        <p className="ReactAdvanced-text">
          Unlock the power of the MERN stack with advanced solutions tailored to your needs.
        </p>
        <div className="ReactAdvanced-grid">
          <div className="ReactAdvanced-card">
            <FaDatabase className="ReactAdvanced-icon" />
            <h3 className="ReactAdvanced-card-title">MongoDB Integration</h3>
            <p className="ReactAdvanced-card-text">
              Seamlessly integrate MongoDB for data storage and management.
            </p>
          </div>
          <div className="ReactAdvanced-card">
            <FaCogs className="ReactAdvanced-icon" />
            <h3 className="ReactAdvanced-card-title">API Development</h3>
            <p className="ReactAdvanced-card-text">
              Build RESTful and GraphQL APIs with Node.js and Express for dynamic applications.
            </p>
          </div>
        </div>
      </section>
    </section>
  );
};

export default ReactWebsite;
