import React from 'react'
import FacebookMarketing from './FacebookMarketing/FacebookMarketing'
import InstagramMarketing from './InstagramMarketing/Instagram'
import LinkedInMarketing from './LinkdinMarketing/LinkdinMarketing'

const SocialMedia = () => {
  return (
  <>
  <FacebookMarketing/>
  <InstagramMarketing/>
  <LinkedInMarketing/>
  </>
  )
}

export default SocialMedia