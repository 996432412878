import React from "react";
import "./ClientTestimonial.css"; // Updated CSS file

const testimonials = [
  {
    name: "John Smith",
    title: "CEO of Tech Innovations",
    image: require("../../Assest/1.webp"),
    feedback:
      "Webixgo has been a key player in improving our online presence. As a top SEO Company in Amritsar, their strategies helped us rank higher and drive more traffic to our site. Their PPC campaigns and social media marketing have boosted engagement, while their web design team created a user-friendly, responsive site. Webixgo delivers exceptional results, and we highly recommend their services.",
  },
  {
    name: "Sarah Johnson",
    title: "Marketing Director at GreenWave Solutions",
    image: require("../../Assest/2.webp"),
    feedback:
      "Working with Webixgo, a leading Web Development Company in Amritsar, was a game-changer. They revamped our e-commerce platform with great web design and app development services. Their SEO optimization increased our site traffic, and their PPC campaigns brought excellent ROI. Webixgo's team truly understands the needs of businesses looking for digital growth",
  },
  {
    name: "David Lee",
    title: "Founder of Urban Eats",
    image: require("../../Assest/3.webp"),
    feedback:
      "Webixgo helped us enhance our brand's digital presence. As a Graphic Designing Company in Amritsar, they delivered stunning visuals, and their SEO optimization boosted our search engine ranking. Their PPC campaigns brought measurable results. If you’re looking for a complete digital marketing solution, Webixgo is the team to trust.",
  },
];

const TestimonialCard = ({ name, title, image, feedback }) => (
  <div className="testimonial-card">
    <img
      src={image || "/path-to-fallback-image.webp"}
      alt={`${name}`}
      className="testimonial-image"
      loading="lazy"
    />
    <h3 className="testimonial-name">{name}</h3>
    <p className="testimonial-title">{title}</p>
    <p className="testimonial-feedback">"{feedback}"</p>
  </div>
);

const ClientTestimonials = () => {
  return (
    <div
      className="testimonials-container"
      role="region"
      aria-labelledby="testimonials-title"
    >
      <h2 id="testimonials-title" className="testimonials-title">
        What Our Clients Say About Our Digital Marketing Services
      </h2>
      <p className="testimonials-description">
        At GloryWebs, client satisfaction is our top priority. We are proud of
        the results we’ve achieved for our clients, and their testimonials
        reflect the impact of our digital marketing services. Our commitment to
        delivering exceptional service and measurable success has earned us the
        trust of businesses across various industries. Here’s what some of our
        clients have to say about partnering with us.
      </p>
      <div className="testimonials-grid">
        {testimonials.map((testimonial, index) => (
          <TestimonialCard
            key={index}
            name={testimonial.name}
            title={testimonial.title}
            image={testimonial.image}
            feedback={testimonial.feedback}
          />
        ))}
      </div>
    </div>
  );
};

export default ClientTestimonials;
