import React from "react";
import "./ecommerce-design.css";

const Ecommercedesign = () => {
  return (
    <>
      <div className="ecommerce-container">
        {/* Header Section */}
        <div className="ecommerce-banner">
          <h1>Why Ecommerce Design is Crucial for Your Business</h1>
          <p>Enhance User Experience and Drive Online Sales</p>
          <p>
          At Webixgo, we specialize in E-commerce Design that ensures your online store not only looks attractive but also provides an exceptional user experience. As a leading e-commerce design company, we focus on creating visually appealing, easy-to-navigate, and conversion-optimized e-commerce websites. Our designs are tailored to your brand’s identity, ensuring your customers have a seamless shopping experience across all devices.

Our expert team uses modern design principles, intuitive navigation, and responsive layouts to enhance user engagement and drive conversions. Whether you need a custom e-commerce platform or want to optimize your existing site, our e-commerce design services ensure your store is both functional and visually captivating. By focusing on user experience (UX) and user interface (UI), we help you create an e-commerce store that attracts customers and boosts sales. With Webixgo, your e-commerce design is built to maximize customer satisfaction and increase your revenue.
          </p>
        </div>

        {/* Benefits Section */}
        <div className="ecommerce-benefits">
          <div className="ecommerce-box green-box">
            <h3>Benefits of Ecommerce Design:</h3>
            <ul>
              <li>Provide seamless shopping experiences</li>
              <li>Improve customer trust and brand loyalty</li>
              <li>Boost sales with user-friendly interfaces</li>
              <li>Optimize for mobile and desktop users</li>
              <li>Increase ROI with advanced features</li>
            </ul>
          </div>
          <div className="ecommerce-box orange-box">
            <h3>Challenges Without a Modern Ecommerce Design:</h3>
            <ul>
              <li>Poor user experience and lower engagement</li>
              <li>Decreased customer retention rates</li>
              <li>Reduced sales and conversion rates</li>
              <li>Difficulty competing in a digital market</li>
            </ul>
          </div>
        </div>

        {/* Features Section */}
        <div className="ecommerce-features">
          <h2>Why Choose Our Ecommerce Design Services</h2>
          <p>
            Here are six key features that make our ecommerce design services stand out:
          </p>
          <div className="ecommerce-grid">
            <div className="grid-item">
              <div className="icon custom-app-icon">
                <i className="fas fa-shopping-cart"></i>
              </div>
              <h3>Custom Design Solutions</h3>
              <p>
                Tailor-made ecommerce platforms to suit your business needs.
              </p>
            </div>
            <div className="grid-item">
              <div className="icon ui-ux-icon">
                <i className="fas fa-paint-brush"></i>
              </div>
              <h3>UI/UX Design</h3>
              <p>
                Create intuitive and visually appealing designs for enhanced user experience.
              </p>
            </div>
            <div className="grid-item">
              <div className="icon performance-icon">
                <i className="fas fa-tachometer-alt"></i>
              </div>
              <h3>High Performance</h3>
              <p>
                Deliver fast, secure, and reliable platforms for optimal performance.
              </p>
            </div>
            <div className="grid-item">
              <div className="icon integration-icon">
                <i className="fas fa-plug"></i>
              </div>
              <h3>Seamless Integration</h3>
              <p>
                Ensure compatibility with payment gateways and other systems.
              </p>
            </div>
            <div className="grid-item">
              <div className="icon maintenance-icon">
                <i className="fas fa-tools"></i>
              </div>
              <h3>Maintenance & Support</h3>
              <p>
                Provide ongoing support to keep your platform running smoothly.
              </p>
            </div>
            <div className="grid-item">
              <div className="icon scalability-icon">
                <i className="fas fa-expand-arrows-alt"></i>
              </div>
              <h3>Scalability</h3>
              <p>
                Build scalable platforms to grow alongside your business.
              </p>
            </div>
          </div>
        </div>

        {/* Process Section */}
        <div className="ecommerce-process">
          <h2>Our Ecommerce Design Process</h2>
          <p>We follow a structured approach to deliver exceptional results:</p>

          <div className="process-stages">
            <div className="stage">
              <h3 className="primary-color">Plan</h3>
              <p>
                Understand your requirements and create a comprehensive design plan.
              </p>
            </div>
            <div className="stage">
              <h3 className="primary-color">Design</h3>
              <p>
                Develop user-centric designs to enhance usability and appeal.
              </p>
            </div>
            <div className="stage">
              <h3 className="primary-color">Develop</h3>
              <p>
                Build robust and feature-rich platforms using the latest technologies.
              </p>
            </div>
            <div className="stage">
              <h3 className="primary-color">Deploy</h3>
              <p>
                Launch your platform with thorough testing and quality assurance.
              </p>
            </div>
          </div>

          <p className="measure-line">Plan → Design → Develop → Deploy</p>
        </div>
      </div>
    </>
  );
};

export default Ecommercedesign;