import React from "react";
import "./LocalSEO.css";
import img1 from "../../../Assest/2.webp"


const LocalSEO = () => {
  return (
    <>

    <div className="local-seo-container">
      {/* Banner Section */}
      <section className="local-seo-banner">
        <h1>Unlock Your Local SEO Potential</h1>
        <p>Boost your visibility and connect with your local audience today!</p>
      </section>

      {/* About Local SEO Section */}
      <section className="local-seo-about">
        <div className="local-seo-about-text">
          <h2>What is Local SEO?</h2>
          <p>
          In an increasingly competitive digital marketplace, Local SEO is essential for businesses looking to reach customers within a specific geographic area. At Webixgo, we specialize in helping businesses optimize their online presence for local search results. Our Local SEO strategies include optimizing your Google My Business listing to ensure your business appears on Google Maps and local search results.

We work on ensuring consistent NAP (Name, Address, Phone Number) information across the web, which is crucial for local ranking. Our team also builds citations in trusted online directories and gathers reviews to enhance your local credibility. Additionally, we optimize your website’s content and structure for location-based keywords, ensuring you appear when customers in your area are searching for your products or services. With our tailored Local SEO services, we help increase your visibility, drive foot traffic to your physical location, and boost your local sales. Whether you run a restaurant, retail store, or local service provider, Webixgo ensures your business thrives in local search results.
</p>
        </div>
        <div className="local-seo-about-image">
          <img
            src={img1}
            alt="Local SEO illustration"
          />
        </div>
      </section>

      {/* Ranking Factors and How-To Section */}
      <section className="local-seo-content">
        <div className="local-seo-flex-container">
          <div className="local-seo-text">
            <h2>What are local SEO ranking factors?</h2>
            <ul>
              <li>Google Business Profile or Google My Business (GMB) listing</li>
              <li>
                Name, address, and phone number (NAP) consistency in local
                business citations
              </li>
              <li>Domain authority</li>
              <li>Quality of local search citations</li>
              <li>Click-through rate (CTR) from search results</li>
              <li>Keywords</li>
              <li>User experience (UX)</li>
              <li>Review quality, velocity, and diversity</li>
              <li>Link signals</li>
            </ul>
          </div>

          <div className="local-seo-text">
            <h2>How to do local SEO?</h2>
            <ul>
              <li>Local business keyword targeting</li>
              <li>
                Google Business Profile or Google My Business optimization
              </li>
              <li>Local search citations</li>
              <li>Online reputation management</li>
              <li>Link building</li>
              <li>On-page SEO</li>
              <li>SEO local landing page optimization</li>
              <li>Google local SEO content marketing</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Additional Section */}
      <section className="local-seo-extra">
        <h2>Why Choose Local SEO?</h2>
        <p>
          Local SEO helps you stand out in your community, attract more customers, and build long-lasting relationships with your audience. With a tailored approach to your local area, you can achieve measurable growth and stay ahead of your competitors.
        </p>

      </section>
    </div>

    </>
  );
};

export default LocalSEO;