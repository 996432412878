import React from "react";
import "./DigitalMarketingProcess.css";
import centerImage from "../../Assest/webixlogo.png"; // Replace with your image path
import {
  FaSearch,
  FaLightbulb,
  FaMapMarkerAlt,
  FaFlag,
  FaLaptopCode,
  FaBullseye,
} from "react-icons/fa";


const DigitalMarketingProcess = () => {
  const cards = [
    {
      id: 1,
      icon: <FaSearch />,
      title: "Discovery & Research",
      description: "We dive deep into understanding your business, industry, and audience to build a strong foundation for a tailored digital marketing strategy.",
    },
    {
      id: 2,
      icon: <FaLightbulb />,
      title: "Strategy Development",
      description: "We develop a comprehensive digital marketing plan customized to your business goals, ensuring every effort aligns with your objectives.",
    },
    {
      id: 3,
      icon: <FaMapMarkerAlt />,
      title: "Execution",
      description: "Our team brings the strategy to life, implementing it across all relevant digital channels with creativity and precision.",
    },
    {
      id: 4,
      icon: <FaFlag />,
      title: "Monitoring & Optimization",
      description: "We track campaign performance in real-time, making data-driven tweaks to maximize results and ROI.",
    },
    {
      id: 5,
      icon: <FaLaptopCode />,
      title: "Reporting & Analysis",
      description: "We deliver detailed reports that provide clear insights into campaign performance, keeping you informed.",
    },
    {
      id: 6,
      icon: <FaBullseye />,
      title: "Ongoing Support",
      description: "We offer continuous support and updates, refining strategies to ensure your digital marketing efforts stay ahead.",
    },
  ];

  return (
    <>

    <section className="serviceshome-section">
      {/* Section Heading and Description */}
      <div className="serviceshome-header">
        <h2 className="serviceshome-header-title">Our Digital Marketing Services Process</h2>

        <p className="serviceshome-header-description">

        Empower Your Business with Webixgo – Leading Digital Marketing Company in Amritsar

At Webixgo, we specialize in transforming your digital presence through innovative strategies and expert execution, making your brand stand out in today’s competitive landscape. As a premier Social Media Company in Amritsar, we help businesses build meaningful connections with their audience by creating engaging content and fostering active online communities. Whether you want to boost brand visibility or drive customer engagement, our social media expertise ensures your goals are met with precision.

Our reputation as a top SEO Company in Amritsar stems from our ability to enhance your online visibility and bring organic traffic to your website. By optimizing your content and implementing result-driven SEO strategies, we ensure your business consistently ranks higher in search engine results, making it easier for potential customers to find you.

From being a trusted Graphic Designing Company in Amritsar to a reliable Web Development Company in Amritsar, we offer a full spectrum of services that cater to your digital marketing needs. Our creative team develops visually striking graphics that resonate with your brand identity and leave a lasting impression on your audience. Additionally, our web development expertise ensures that your online presence is not only aesthetically pleasing but also functional, responsive, and user-friendly.

Whether it’s crafting compelling content, designing captivating visuals, or executing high-performing campaigns, Webixgo delivers measurable results that fuel your business growth. Our team is dedicated to creating customized strategies tailored to your unique objectives, helping you achieve sustainable success in the ever-evolving digital world.</p>
      </div>

      <div className="serviceshome-container">
        {/* Center Image */}
        <div className="center-image">
          <img src={centerImage} alt="Center" />
        </div>

        {/* Cards */}
        {cards.map((card, index) => (
          <div key={card.id} className={`card card-${index + 1}`}>
            <div className="icon">{card.icon}</div>
            <h3 className="card-title">{card.title}</h3>
            <p className="card-description">{card.description}</p>
          </div>
        ))}
      </div>
    </section>
    </>
  );
};

export default DigitalMarketingProcess;
