import React from "react";
import "./Industryweserve.css";
import {
  FaHamburger,
  FaShoppingBag,
  FaSpa,
  FaHeartbeat,
  FaHospital,
  FaGraduationCap,
  FaPlane,
  FaCar,
  FaLaptop,
  FaBook,
  FaMoneyCheckAlt,
  FaBuilding,
  FaToolbox,
  FaHome,
  FaRecycle,
  FaStar,
  FaShippingFast,
  FaBoxOpen,
  FaTractor,
  FaWrench,
} from "react-icons/fa";


const categories = [
  { name: "Food and Beverage", icon: <FaHamburger />, color: "#f0932b", description: "Restaurants and Cafes" },
  { name: "Retail and Shopping", icon: <FaShoppingBag />, color: "#6ab04c", description: "Supermarkets and Stores" },
  { name: "Beauty and Personal Care", icon: <FaSpa />, color: "#eb4d4b", description: "Salons and Spas" },
  { name: "Health and Fitness", icon: <FaHeartbeat />, color: "#22a6b3", description: "Gyms and Wellness Centers" },
  { name: "Healthcare and Medical Services", icon: <FaHospital />, color: "#4834d4", description: "Clinics and Hospitals" },
  { name: "Education and Learning", icon: <FaGraduationCap />, color: "#130f40", description: "Schools and Colleges" },
  { name: "Travel and Tourism", icon: <FaPlane />, color: "#f9ca24", description: "Travel Agencies" },
  { name: "Transportation and Automotive", icon: <FaCar />, color: "#6c5ce7", description: "Car Rentals and Services" },
  { name: "Technology and Gadgets", icon: <FaLaptop />, color: "#00cec9", description: "Electronics Stores" },
  { name: "Books and Stationary", icon: <FaBook />, color: "#d63031", description: "Bookstores" },
  { name: "Financial Services", icon: <FaMoneyCheckAlt />, color: "#0984e3", description: "Banks and Insurance" },
  { name: "Real Estate", icon: <FaBuilding />, color: "#b2bec3", description: "Property Dealers" },
  { name: "Miscellaneous and Niche Stores", icon: <FaToolbox />, color: "#fd79a8", description: "Custom Services" },
  { name: "Repair Services", icon: <FaWrench />, color: "#fab1a0", description: "Repair Shops" },
  { name: "Home and Garden", icon: <FaHome />, color: "#55efc4", description: "Furniture and Decor" },
  { name: "Plastic and Packaging", icon: <FaBoxOpen />, color: "#636e72", description: "Packaging Solutions" },
  { name: "Construction and Material", icon: <FaTractor />, color: "#ffeaa7", description: "Builders and Suppliers" },
  { name: "Specialized Services", icon: <FaStar />, color: "#fdcb6e", description: "Premium Services" },
  { name: "Luxury and Exclusive Services", icon: <FaShippingFast />, color: "#e17055", description: "High-End Solutions" },
  { name: "Recycling and Waste Management", icon: <FaRecycle />, color: "#81ecec", description: "Eco-Friendly Services" },
];

const Industryweserve = () => {
  const halfLength = Math.ceil(categories.length / 2);
  const firstRowCategories = [...categories.slice(0, halfLength), ...categories.slice(0, halfLength)];
  const secondRowCategories = [...categories.slice(halfLength), ...categories.slice(halfLength)];

  return (
    <>

    <div className="industryweserve-container">
      <h2 className="industryweserve-header">Industries We Serve</h2>
      <p className="industryweserve-description">
        We cater to a diverse range of industries with specialized services tailored to meet the unique needs of each sector. Explore the industries we proudly serve.
      </p>
      <div className="industryweserve-scroll-container">
        {/* First row */}
        <div className="industryweserve-scroll-track">
          {firstRowCategories.map((category, index) => (
            <div className="industryweserve-flip-box" key={`row1-${index}`}>
              <div className="industryweserve-flip-box-inner">
                <div
                  className="industryweserve-flip-box-front"
                  style={{ backgroundColor: category.color }}
                >
                  <div className="industryweserve-card-icon">{category.icon}</div>
                  <p className="industryweserve-card-name">{category.name}</p>
                </div>
                <div
                  className="industryweserve-flip-box-back"
                  style={{ backgroundColor: category.color }}
                >
                  <p className="industryweserve-card-description">{category.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* Second row */}
        <div className="industryweserve-scroll-track reverse">
          {secondRowCategories.map((category, index) => (
            <div className="industryweserve-flip-box" key={`row2-${index}`}>
              <div className="industryweserve-flip-box-inner">
                <div
                  className="industryweserve-flip-box-front"
                  style={{ backgroundColor: category.color }}
                >
                  <div className="industryweserve-card-icon">{category.icon}</div>
                  <p className="industryweserve-card-name">{category.name}</p>
                </div>
                <div
                  className="industryweserve-flip-box-back"
                  style={{ backgroundColor: category.color }}
                >
                  <p className="industryweserve-card-description">{category.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
    </>
  );
};

export default Industryweserve;
