import React from "react";
import "./EnterpriseMarketing.css";
import { FaChartBar, FaDatabase, FaGlobe, FaPalette } from "react-icons/fa";
import img1 from "../../../Assest/strategy.webp";
import img2 from "../../../Assest/scability.webp";
import img3 from "../../../Assest/datadriven.webp";
import img4 from "../../../Assest/brandconsistency.webp";




const EnterpriseMarketing = () => {
  return (
    <>
      
      <div className="enterprise-marketing-page">
        {/* Banner Section */}
        <section className="enterprise-marketing-page-banner">
          <h1>Enterprise Digital Marketing</h1>
          <p>Bigger Brand Reach. Next-Level Strategies.</p>
        
        </section>

        {/* Text and Image Section */}
        <section className="enterprise-marketing-page-text-image">
          <div className="text-image-text">
            <h2 style={{ color: "var(--primary-color)" }}>
              Engage and Convert Customers on the Right Platforms
            </h2>
            <p>
            For large businesses, Webixgo offers comprehensive enterprise marketing solutions that drive global growth. We specialize in creating integrated strategies that incorporate SEO, PPC advertising, social media marketing, and content marketing. With our expertise in web development and data analytics, we help enterprises scale their marketing efforts and reach a wider audience. Our team provides continuous performance analysis and optimization, ensuring your campaigns achieve high ROI. Webixgo’s enterprise marketing services are designed to deliver long-term success and business growth. Let us help you manage your enterprise’s marketing campaigns efficiently while boosting customer engagement and brand loyalty.
            </p>
            <p>
              Leverage enterprise marketing services to dominate your industry:
            </p>
            <ul>
              <li>Customized strategies aligned with your business objectives</li>
              <li>Comprehensive analytics and performance tracking</li>
              <li>Seamless integration with your existing marketing platforms</li>
              <li>
                Expert team with decades of experience in digital transformation
              </li>
            </ul>
          </div>
          <div className="text-image-image">
            <img src={img1} alt="Enterprise marketing" />
          </div>
        </section>

        {/* Why Choose Section */}
        <section className="enterprise-marketing-page-why-choose">
          <h2>Why Your Business Needs Enterprise Digital Marketing Services</h2>
          <div className="why-choose-grid">
            <div className="why-choose-item">
              <FaChartBar size={50} color="white" />
              <h3>Scalability</h3>
              <p>
                As your business grows, so do your marketing needs. Enterprise
                strategies ensure seamless scaling without sacrificing quality.
              </p>
            </div>
            <div className="why-choose-item">
              <FaDatabase size={50} color="white" />
              <h3>Data-Driven</h3>
              <p>
                Big organizations face unique data challenges. We provide advanced
                analytics and insights for smarter decisions.
              </p>
            </div>
            <div className="why-choose-item">
              <FaGlobe size={50} color="white" />
              <h3>Go Global</h3>
              <p>
                Leverage data-driven solutions to maximize reach and ensure cohesive
                strategies for international markets.
              </p>
            </div>
            <div className="why-choose-item">
              <FaPalette size={50} color="white" />
              <h3>Brand Consistency</h3>
              <p>
                Maintain a unified voice and message across all channels, boosting
                trust and recognition.
              </p>
            </div>
          </div>
        </section>

        {/* Case Studies Section */}
        <section className="enterprise-marketing-page-case-studies">
          <h2>Webixgo Digital Marketing Enterprise Case Studies</h2>
          <div className="case-studies-grid">
            <div className="case-study-card">
              <img src={img2} alt="Case Study 1" />
              <h3>Performance Growth</h3>
              <p>+260% ROI in 12 months</p>
            </div>
            <div className="case-study-card">
              <img src={img3} alt="Case Study 2" />
              <h3>Shoe Company</h3>
              <p>387% increase in online sales</p>
            </div>
            <div className="case-study-card">
              <img src={img4} alt="Case Study 3" />
              <h3>Mobile App Development</h3>
              <p>+164% app downloads</p>
            </div>
          </div>
        </section>

        {/* Call to Action Section */}
        <section className="enterprise-marketing-page-cta">
          <h2>Boost Your Brand with Us</h2>
          <p>
            Partner with a top-tier digital marketing company to supercharge your
            strategy.
          </p>

        </section>
      </div>

    </>
  );
};

export default EnterpriseMarketing;
