import React from "react";
import "./Copywriteservices.css";

const Copywriteservices = () => {
  return (
    <div className="copyrightservices">
      {/* Banner Section */}
      <section className="copyrightservices__banner">
        <h1>Copyright Services</h1>
        <p>Protect Your Intellectual Property with Expert Copyright Services</p>
      </section>

      {/* Introduction Section */}
      <section className="copyrightservices__introduction">
        <h2>What Are Copyright Services?</h2>
        <p>
        Copywriting Services in Amritsar play a vital role in converting visitors into customers by crafting compelling, persuasive content that speaks directly to your target audience. At Webixgo, our copywriting services are designed to increase engagement, drive sales, and improve brand awareness. As a leading copywriting company in Amritsar, we specialize in creating catchy and persuasive copy for websites, advertisements, social media, email campaigns, and more.

We focus on writing clear, concise, and action-oriented content that resonates with your audience and pushes them to take the next step—whether it’s making a purchase, signing up for a newsletter, or contacting your team. Our copywriting services in Amritsar are optimized for conversion, ensuring that every word serves a purpose in moving your potential customers closer to a sale. Let Webixgo craft compelling copy that connects with your audience and boosts your business’s bottom line.
        </p>
       
      </section>

      {/* Benefits Section */}
      <section className="copyrightservices__benefits">
        <h2>Why Copyright Protection Is Essential</h2>
        <ul>
          <li>Preserve the value of your creative assets.</li>
          <li>Prevent unauthorized reproduction or distribution.</li>
          <li>Enhance your ability to license and monetize your work.</li>
          <li>Gain legal recourse against copyright infringement.</li>
        </ul>
      </section>

      {/* Card Section */}
      <section className="copyrightservices__cards">
        <h2>Our Copyright Services</h2>
        <div className="copyrightservices__cards-container">
          <div className="copyrightservices__card">
            <i className="copyrightservices__icon fas fa-file-contract"></i>
            <h3>Registration Assistance</h3>
            <p>
              Expert guidance through the copyright registration process to ensure your work is legally protected.
            </p>
          </div>
          <div className="copyrightservices__card">
            <i className="copyrightservices__icon fas fa-gavel"></i>
            <h3>Legal Support</h3>
            <p>
              Comprehensive support for enforcing your copyrights and addressing infringement issues.
            </p>
          </div>
          <div className="copyrightservices__card">
            <i className="copyrightservices__icon fas fa-pen-fancy"></i>
            <h3>Licensing Agreements</h3>
            <p>
              Assistance in creating and managing copyright licensing agreements to monetize your work.
            </p>
          </div>
        </div>
      </section>

      {/* Additional Section */}
      <section className="copyrightservices__additional">
        <h2>Get Started with Copyright Services</h2>
        <p>
          Protect your creative works today with our comprehensive copyright solutions. Contact us to learn more about how we can help secure your intellectual property.
        </p>
  
      </section>
    </div>
  );
};

export default Copywriteservices;