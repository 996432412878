import React from 'react';
import './TechnicalSEO.css';
import img1 from "../../../Assest/3.webp"


const TechnicalSEO = () => {
    return (
        <>
        
    
        <div className="technical-seo-container">
        {/* Banner Section */}
        <section className="technical-seo-banner">
          <h1 className="technical-seo-banner-title">Technical SEO</h1>
          <p className="technical-seo-banner-description">
            Optimize your website with our advanced technical SEO solutions.
          </p>
        </section>
  
        {/* One Side Text, One Side Image */}
        <section className="technical-seo-content">
          <div className="technical-seo-text">
            <h2 className="technical-seo-content-title">Why Choose Our Technical SEO Services?</h2>
            <p className="technical-seo-content-description">
            Technical SEO ensures that your website is set up correctly for search engines to crawl, index, and rank it effectively. At Webixgo, we provide expert Technical SEO services that address the back-end elements of your site. Our team conducts thorough technical audits to identify issues such as slow page load times, broken links, and duplicate content that may negatively affect performance. We work on optimizing your website's structure, ensuring that it's mobile-friendly, secure (with HTTPS), and easy to navigate.

We also focus on schema markup, which helps search engines understand your website's content more effectively, leading to better visibility in search results. By optimizing elements like image alt texts, sitemaps, and robots.txt files, we ensure search engines can easily index and rank your website. With Technical SEO, we help enhance your website’s user experience and its performance in search engine rankings. By addressing these technical issues, your website will load faster, be more accessible to search engines, and ultimately drive more organic traffic, contributing to better user retention and conversion rates.
            </p>
          </div>
          <div className="technical-seo-image">
            <img src={img1} alt="Technical SEO" className="technical-seo-image-img" />
          </div>
        </section>

            <section className="technicalSeo-section">
                <h2>Our Key Services</h2>
                <div className="technicalSeo-services">
                    <div className="technicalSeo-service">
                        <h3>Technical SEO Audit</h3>
                        <p>We perform a comprehensive SEO technical audit to identify and fix your website’s technical SEO issues. The audit covers vital checkpoints, including mobile performance, internal link structure, page speed, XML sitemap, indexation, crawl errors, robots.txt and site architecture. Using the analytics results, we develop short- and long-term solutions to improve your visitors’ website experience and increase your Google rankings.</p>
                    </div>
                    <div className="technicalSeo-service">
                        <h3>Competitor Analysis</h3>
                        <p>Get a complete overview of your competition’s optimization tactics and determine your conversion opportunities. Our technical SEO consultant evaluates your competition’s keyword performance, search engine rankings, social media presence and technical and backlink profile. These processes allow us to create strategies that will provide you with a distinct advantage in your respective niche market.</p>
                    </div>
                    <div className="technicalSeo-service">
                        <h3>Meta Data Analysis</h3>
                        <p>Improve your online visibility and gain more traffic with meta tags that effectively describe your page content to web visitors and search engines. Thrive performs technical SEO for WordPress, which involves analyzing and optimizing your meta descriptions, title tags, meta keywords, alt attributes and robots tags. In this way, we ensure no misused metadata could severely harm your SEO performance.</p>
                    </div>
                </div>
            </section>

            <section className="technicalSeo-section">
                <h2>Additional Services</h2>
                <div className="technicalSeo-services">
                    <div className="technicalSeo-service">
                        <h3>Image Optimization</h3>
                        <p>Large images slow down your site performance and drive away page visitors. Reduce your site load time and attract more customers with technical SEO for eCommerce. Our specialists choose the right file format, optimize your alt attributes, reduce the file size of your images, utilize image sitemaps and maximize image optimization plugins.</p>
                    </div>
                    <div className="technicalSeo-service">
                        <h3>Speed Optimization</h3>
                        <p>Page speed affects your ranking and, more importantly, your customers’ online experience. Our technical SEO specialists enable file compression to reduce the size of your CSS, JavaScript and HTML files. We also optimize your code, reduce redirects, leverage browser caching, utilize content distribution networks (CDNs) and improve server response time.</p>
                    </div>
                    <div className="technicalSeo-service">
                        <h3>Structured Data Markup</h3>
                        <p>Make it easier for search engines to crawl, interpret and display your content using schema markup. We highlight your page elements, assign data tags, create the HTML and add the schema markup to your page. Our team utilizes Google’s Structured Data Testing Tool to test your markup and diagnose any issues.</p>
                    </div>
                </div>
            </section>

            <section className="technicalSeo-section">
                <h2>Essential SEO Strategies</h2>
                <div className="technicalSeo-services">
                    <div className="technicalSeo-service">
                        <h3>Keyword Research</h3>
                        <p>Keyword research is the blueprint of your digital marketing strategy. Get insights into current industry trends and consumer behavior and ensure appropriate keyword usage throughout your website. Our SEO specialists study your niche and perform technical SEO audits to determine your keyword rankings and optimize your web pages with high-performing search terms.</p>
                    </div>
                    <div className="technicalSeo-service">
                        <h3>Backlink Analysis</h3>
                        <p>Gain a better understanding of your website’s online authority and search ranking capabilities with technical SEO for eCommerce and other platforms. Our team performs backlink analyses to ensure you acquire incoming links from reputable and high-authority websites. We utilize various backlink data sources to identify your referring domains, check their site authority and determine the social share magnitude of the linking pages.</p>
                    </div>
                </div>
            </section>
        </div>
    
        </>
    );
};

export default TechnicalSEO;
