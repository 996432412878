import React from "react";
import "./FacebookMarketing.css";
import { FaChartBar, FaDatabase, FaGlobe, FaPalette } from "react-icons/fa";
import img1 from "../../../Assest/fb.jpg";


const FacebookMarketing = () => {
  return (
    <>
    
      <div className="facebook-marketing-page">
        {/* Banner Section */}
        <section className="facebook-marketing-page-banner">
          <h1>Facebook Marketing</h1>
          <p>Maximize Your Reach with Tailored Facebook Campaigns</p>
         
        </section>

        {/* Text and Image Section */}
        <section className="facebook-marketing-page-text-image">
          <div className="text-image-text">
            <h2 style={{ color: "var(--primary-color)" }}>
              Engage and Convert Customers on Facebook
            </h2>
            <p>
            Facebook Marketing is one of the most effective ways to connect with your target audience, build brand awareness, and drive conversions. As a leading digital marketing company, Webixgo offers expert Facebook marketing services to help you leverage the full potential of this platform. We create customized campaigns that focus on your business goals, ensuring you reach the right audience through precise targeting.

Our team develops high-quality Facebook ads, organic content strategies, and engagement tactics to help your business grow its online presence. Whether it's increasing page likes, driving website traffic, or generating leads, we optimize each campaign to deliver measurable results. With Facebook marketing, we can help you create a strong community around your brand, engage with your customers, and ultimately drive sales. By using Facebook’s powerful analytics tools, we continuously refine strategies to maximize ROI.
            </p>
            <p>
              Leverage Facebook marketing services to dominate your industry:
            </p>
            <ul>
              <li>Customized strategies aligned with your Facebook goals</li>
              <li>Comprehensive analytics and performance tracking</li>
              <li>Seamless integration with your existing Facebook marketing platforms</li>
              <li>Expert team with decades of experience in social media marketing</li>
            </ul>
          </div>
          <div className="text-image-image">
            <img src={img1} alt="Facebook Marketing" />
          </div>
        </section>

        {/* Why Choose Section */}
        <section className="facebook-marketing-page-why-choose">
          <h2 className="facebook-whychoose">Why Your Business Needs Facebook Marketing Services</h2>
          <div className="why-choose-grid">
            <div className="why-choose-item">
              <FaChartBar size={50} color="white" />
              <h3>Scalability</h3>
              <p>
                As your business grows, so does your Facebook marketing potential. Our strategies scale with your growth.
              </p>
            </div>
            <div className="why-choose-item">
              <FaDatabase size={50} color="white" />
              <h3>Data-Driven</h3>
              <p>
                We provide deep insights into your Facebook campaigns, optimizing them based on data-driven analysis.
              </p>
            </div>
            <div className="why-choose-item">
              <FaGlobe size={50} color="white" />
              <h3>Go Global</h3>
              <p>
                Expand your reach internationally with Facebook’s advanced targeting and global capabilities.
              </p>
            </div>
            <div className="why-choose-item">
              <FaPalette size={50} color="white" />
              <h3>Brand Consistency</h3>
              <p>
                Ensure a unified brand presence across all your Facebook campaigns to build trust and recognition.
              </p>
            </div>
          </div>
        </section>

        {/* Case Studies Section */}
        <section className="facebook-marketing-page-case-studies">
          <h2 className="facebook-whychoose">Our Facebook Marketing Case Studies</h2>
          <div className="case-studies-grid">
          </div>
        </section>

        {/* Call to Action Section */}
        <section className="facebook-marketing-page-cta">
          <h2>Boost Your Brand with Facebook Marketing</h2>
          <p>
            Partner with a top-tier Facebook marketing company to supercharge your brand’s social presence.
          </p>
    
        </section>

        {/* Our Facebook Marketing Services */}
        <section className="facebook-marketing-page-services">
          <h2 className="facebook-whychoose">Our Facebook Marketing Services</h2>
          <p>
            With everything from strategy creation to campaign analysis, we will help you achieve your Facebook objective of choice, whether it be to increase awareness, gain consideration, or earn conversions. We are constantly researching, tracking, and adapting to online trends. Let us be your go-to resource for the most recent Facebook marketing tactics out there.
          </p>
          <div className="services-grid">
            <div className="service-item">
              <h3>Analytics</h3>
              <p>
                We work with data-tracking tools to gain insights that pinpoint where each campaign went right (or wrong), ultimately improving your strategy.
              </p>
            </div>
            <div className="service-item">
              <h3>Audience Identification</h3>
              <p>
                We can identify and assess your audience, determining how best to use Facebook’s powerful targeting capabilities.
              </p>
            </div>
            <div className="service-item">
              <h3>Strategy</h3>
              <p>
                We can create and implement an effective Facebook marketing strategy catered specifically to your brand’s goals.
              </p>
            </div>
            <div className="service-item">
              <h3>Monitoring</h3>
              <p>
                We can monitor your Facebook Page and campaigns, recognizing any opportunities or issues that pop up and responding in turn.
              </p>
            </div>
            <div className="service-item">
              <h3>Paid Ads</h3>
              <p>
                Paid ads are the best way to expand reach and gain traction on Facebook. We can run the process from format selection to final analysis, optimizing your campaign the entire way.
              </p>
            </div>
            <div className="service-item">
              <h3>Content Creation</h3>
              <p>
                We can design and schedule regular updates and on-the-fly news items that will captivate your desired audience.
              </p>
            </div>
          </div>
        </section>

        {/* Our Facebook Marketing Team */}
        <section className="facebook-marketing-page-team">
          <h2 className="facebook-whychoose">Our Facebook Marketing Team</h2>
          <div className="services-grid">
            <div className="service-item">
              <h3>Transparent</h3>
              <p>
                We’ll keep you in-the-know about our tactics—not only those that worked, but also those that didn’t work. Transparent data analysis is key to our process.
              </p>
            </div>
            <div className="service-item">
              <h3>Experienced</h3>
              <p>
                Our team has run campaigns for a variety of industries across the United States, improving Facebook engagement and growth.
              </p>
            </div>
            <div className="service-item">
              <h3>Recommended</h3>
              <p>
                Thrive was named a top social media marketing agency in the United States by Clutch.co.
              </p>
            </div>
          </div>
        </section>
      </div>
   
    </>
  );
};

export default FacebookMarketing;
