import React from 'react'
import LocalSEO from './LocalSEO/LocalSEO'
import EnterpriseSEO from './EnterpriseSEO/EnterpriseSEO'
import TechnicalSEO from './TechnicalSEO/TechnicalSEO'

const SEO = () => {
  return (
    <>
    <LocalSEO/>
    <EnterpriseSEO/>
    <TechnicalSEO/>
    </>
  )
}

export default SEO