import React from "react";
import "./ecommerceppc.css";

const Ecommerceppc = () => {
  return (
    <>
      <div className="ecommerceppc-container">
        {/* Header Section */}
        <div className="ecommerceppc-banner">
          <h1>Why Ecommerce PPC is Crucial for Your Business</h1>
          <p>Drive Targeted Traffic and Maximize Your ROI</p>
          <p>
          E-commerce PPC (Pay-Per-Click) is one of the most effective ways to generate immediate traffic and drive sales for your online store. At Webixgo, we specialize in e-commerce PPC services that focus on maximizing ROI and increasing conversions through targeted ad campaigns. Our team creates custom PPC ads on platforms like Google Ads, Bing Ads, and social media to drive targeted traffic directly to your e-commerce site.

Our e-commerce PPC strategies are designed to attract qualified leads, with ads optimized for search intent and audience segmentation. We carefully manage your PPC budget to ensure that you get the most value from your campaigns, targeting the right keywords and demographics for maximum conversions. Whether you're running Google Shopping Ads, dynamic retargeting ads, or social media ads, Webixgo ensures that your e-commerce PPC campaigns are optimized for success, driving traffic and boosting sales effectively.
          </p>
        </div>

        {/* Benefits Section */}
        <div className="ecommerceppc-benefits">
          <div className="ecommerceppc-box green-box">
            <h3>Benefits of Ecommerce PPC:</h3>
            <ul>
              <li>Increase brand visibility and reach</li>
              <li>Drive high-quality traffic to your site</li>
              <li>Boost sales with targeted advertising</li>
              <li>Track and measure performance effectively</li>
              <li>Achieve higher ROI with data-driven strategies</li>
            </ul>
          </div>
          <div className="ecommerceppc-box orange-box">
            <h3>Challenges Without Ecommerce PPC:</h3>
            <ul>
              <li>Limited visibility in a competitive market</li>
              <li>Reduced customer acquisition and sales</li>
              <li>Inability to target specific audiences</li>
              <li>Difficulty in measuring marketing performance</li>
            </ul>
          </div>
        </div>

        {/* Features Section */}
        <div className="ecommerceppc-features">
          <h2>Why Choose Our Ecommerce PPC Services</h2>
          <p>
            Here are six key features that make our ecommerce PPC services stand out:
          </p>
          <div className="ecommerceppc-grid">
            <div className="grid-item">
              <div className="icon custom-app-icon">
                <i className="fas fa-search-dollar"></i>
              </div>
              <h3>Custom PPC Strategies</h3>
              <p>
                Tailored campaigns to maximize your advertising budget.
              </p>
            </div>
            <div className="grid-item">
              <div className="icon ui-ux-icon">
                <i className="fas fa-chart-line"></i>
              </div>
              <h3>Performance Tracking</h3>
              <p>
                Gain insights with detailed analytics and reporting.
              </p>
            </div>
            <div className="grid-item">
              <div className="icon performance-icon">
                <i className="fas fa-bullseye"></i>
              </div>
              <h3>Targeted Campaigns</h3>
              <p>
                Reach your ideal audience with precision targeting.
              </p>
            </div>
            <div className="grid-item">
              <div className="icon integration-icon">
                <i className="fas fa-plug"></i>
              </div>
              <h3>Seamless Integration</h3>
              <p>
                Ensure smooth integration with ecommerce platforms.
              </p>
            </div>
            <div className="grid-item">
              <div className="icon maintenance-icon">
                <i className="fas fa-tools"></i>
              </div>
              <h3>Ongoing Optimization</h3>
              <p>
                Continuously refine campaigns for better results.
              </p>
            </div>
            <div className="grid-item">
              <div className="icon scalability-icon">
                <i className="fas fa-expand-arrows-alt"></i>
              </div>
              <h3>Scalability</h3>
              <p>
                Scale campaigns to align with your business growth.
              </p>
            </div>
          </div>
        </div>

        {/* Process Section */}
        <div className="ecommerceppc-process">
          <h2>Our Ecommerce PPC Process</h2>
          <p>We follow a structured approach to deliver exceptional results:</p>

          <div className="process-stages">
            <div className="stage">
              <h3 className="primary-color">Plan</h3>
              <p>
                Understand your goals and create a data-driven PPC strategy.
              </p>
            </div>
            <div className="stage">
              <h3 className="primary-color">Design</h3>
              <p>
                Craft compelling ad creatives to maximize engagement.
              </p>
            </div>
            <div className="stage">
              <h3 className="primary-color">Execute</h3>
              <p>
                Launch and manage campaigns to achieve optimal results.
              </p>
            </div>
            <div className="stage">
              <h3 className="primary-color">Optimize</h3>
              <p>
                Continuously monitor and adjust campaigns for better performance.
              </p>
            </div>
          </div>

          <p className="measure-line">Plan → Design → Execute → Optimize</p>
        </div>
      </div>
    </>
  );
};

export default Ecommerceppc;