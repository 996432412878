import React from 'react';
import './FranchiseMarketing.css';
import img1 from "../../../Assest/franchise1.webp"
import customer1 from "../../../Assest/cus1.webp"
import customer2 from "../../../Assest/cus2.webp"

import customer3 from "../../../Assest/cus3.webp"


const FranchiseMarketing = () => {

  return (
    <>
 
    <div className="franchise-marketing">
      {/* Banner Section */}
      <section className="franchise-marketing-banner">
        <h1 className="franchise-marketing-banner-title">Franchise Marketing</h1>
        <p className="franchise-marketing-banner-description">Grow your business with our trusted franchise marketing solutions.</p>
      </section>

      {/* One Side Text, One Side Image */}
      <section className="franchise-marketing-content">
        <div className="franchise-marketing-text">
          <h2 className="franchise-marketing-content-title">Why Choose Our Franchise?</h2>
          <p className="franchise-marketing-content-description">
          Webixgo excels in franchise marketing, helping brands grow their franchise network. As a trusted Digital Marketing Company in Amritsar, we create strong digital campaigns that attract potential franchisees and promote franchise opportunities. We leverage SEO, social media marketing, and PPC campaigns to increase visibility and engagement. By focusing on localized campaigns, we ensure your message reaches the right audience. Our team develops customized marketing plans that align with your franchise goals, creating a seamless experience for both franchisees and customers. With our strategic support, your franchise network will expand with ease and reach new markets effectively.
          </p>
        </div>
        <div className="franchise-marketing-image">
          <img src={img1} alt="Franchise Marketing" className="franchise-marketing-image-img" />
        </div>
      </section>

      {/* Our Franchise Customers */}
      <section className="franchise-marketing-customers">
        <h2 className="franchise-marketing-customers-title">Our Franchise Customers</h2>
        <div className="franchise-marketing-customer-list">
          {/* Customer 1 */}
          <div className="franchise-marketing-customer-item">
            <img
              src={customer1}
              alt="Customer 1"
              className="franchise-marketing-customer-img"
            />
            <h3 className="franchise-marketing-customer-title">Mr. Anil Kumar</h3>
            <p className="franchise-marketing-customer-description">
              A leading brand in the retail industry, providing unmatched services to customers nationwide.
            </p>
          </div>
          {/* Customer 2 */}
          <div className="franchise-marketing-customer-item">
            <img
              src={customer2}
              alt="Customer 2"
              className="franchise-marketing-customer-img"
            />
            <h3 className="franchise-marketing-customer-title">Mr. Shubham Jain</h3>
            <p className="franchise-marketing-customer-description">
              A well-known food chain with a strong market presence and loyal customer base.
            </p>
          </div>
          {/* Customer 3 */}
          <div className="franchise-marketing-customer-item">
            <img
              src={customer3}
              alt="Customer 3"
              className="franchise-marketing-customer-img"
            />
            <h3 className="franchise-marketing-customer-title">Mis Ankita Lahiru</h3>
            <p className="franchise-marketing-customer-description">
              A fitness brand that has transformed thousands of lives through innovative training programs.
            </p>
          </div>
          {/* Add more customers as needed */}
        </div>
      </section>

      {/* Call to Action */}
      <section className="franchise-marketing-cta">
        <h2 className="franchise-marketing-cta-title">Start Your Franchise Journey Today!</h2>
   
      </section>

      {/* Additional Information */}
      <section className="franchise-marketing-more-info">
        <h2 className="franchise-marketing-more-info-title">Why Our Solution Works</h2>
        <p className="franchise-marketing-more-info-description">
          With our data-driven marketing strategies, franchise owners can attract more customers, optimize their marketing budget, and achieve sustainable growth. We offer personalized support for every step of the way.
        </p>
      </section>
    </div>

    </>
  );
}

export default FranchiseMarketing;
