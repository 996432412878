import React from 'react';
import './About2.css';
import teamImage from '../../Assest/monitoring.webp'; // Replace with actual team image path

const About2 = () => {
  return (
    <div className="about-section-container">
      {/* Buttons Section */}
      <div className="button-section">
        <button className="portfolio-button">OUR PORTFOLIO</button>
        <button className="success-button">CLIENT SUCCESS STORIES</button>
      </div>

      {/* Content Section */}
      <div className="content-section">
        {/* Team Image */}
        <div className="image-container">
          <img src={teamImage} alt="Our Team" className="team-image" />
        </div>

        {/* Text Content */}
        <div className="text-container">
          <h2>We Are Digital Marketing Experts</h2>
          <p>
            Webixgo boasts a unique, close-knit team with experts in all facets of web marketing: strategic,
            creative, and technical.
          </p>
          <p>
          Our team consists of skilled professionals from all over the world, including the United States, South Africa, the Philippines, and India, offering diverse skill sets and years of industry expertise. This global presence enables us to stay at the forefront of the latest digital marketing trends, ensuring that we deliver innovative solutions to our clients.

Whether it’s SEO optimization, PPC campaigns, social media marketing, or web development, we specialize in creating custom web solutions that meet your long-term strategic goals. At Webixgo, we understand the importance of a tailored approach, which is why we take the time to understand your unique business needs and develop strategies that are aligned with your objectives.

With a focus on brand growth, customer engagement, and conversion optimization, we ensure that your business stands out in the competitive online landscape. Trust Webixgo to be your dedicated partner in achieving digital success and enhancing your online presence.
          </p>
        
        </div>
      </div>
    </div>
  );
};

export default About2;