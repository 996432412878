import React from "react";
import "./StrategyDevelopment.css";


const StrategyDevelopment = () => {
  return (
    <>

    <div className="strategyDevelopment-container">
      {/* Header Section */}
      <div className="strategyDevelopment-banner">
        <h1>Why You Need to Start With Strategy</h1>
        <p>Uncover Market Opportunities and Increase Your Revenue</p>
        <p>
        Webixgo specializes in crafting tailored marketing strategies to align with your business goals. As an expert SEO Company in Amritsar, we ensure your digital presence is optimized for success. Our strategy development process begins with understanding your brand, target audience, and competitors. We design strategies that integrate SEO, social media marketing, and PPC campaigns to ensure your brand gets noticed. With a focus on data and continuous monitoring, we refine strategies for optimal results, helping businesses achieve long-term growth and success in the digital landscape. Our personalized approach ensures your marketing strategies are always effective and adaptable to market changes.
        </p>
      </div>

      {/* Comparison Section */}
      <div className="strategyDevelopment-comparison">
        <div className="strategyDevelopment-box green-box">
          <h3>A Concrete Digital Marketing Strategy Allows You To:</h3>
          <ul>
            <li>Define clear business goals</li>
            <li>Expand your reach</li>
            <li>Save time and resources</li>
            <li>Discover market opportunities</li>
            <li>Build a brand identity</li>
          </ul>
        </div>
        <div className="strategyDevelopment-box orange-box">
          <h3>Without a Proper Marketing Strategy, You Risk:</h3>
          <ul>
            <li>Missing growth opportunities</li>
            <li>Wasting marketing budgets</li>
            <li>Failing to generate insights</li>
            <li>Lagging behind competitors</li>
          </ul>
        </div>
      </div>

      {/* Agency Features Section */}
      <div className="strategyDevelopment-agency">
        <h2>Our Marketing Strategy Agency Considers Your Unique Industry</h2>
        <p>
          Here are six key considerations to help you choose the right digital
          strategy partner:
        </p>
        <div className="strategyDevelopment-grid">
          <div className="grid-item">
            <div className="icon web-audit-icon">
              <i className="fas fa-search"></i>
            </div>
            <h3>Web Audit Practices</h3>
            <p>
              Conduct a complete digital marketing audit to clarify website
              issues and provide actionable insights.
            </p>
          </div>
          <div className="grid-item">
            <div className="icon ecommerce-icon">
              <i className="fas fa-shopping-cart"></i>
            </div>
            <h3>eCommerce Marketing</h3>
            <p>
              Build a solid eCommerce strategy to meet your customers' demands
              and drive consistent growth.
            </p>
          </div>
          <div className="grid-item">
            <div className="icon advertising-icon">
              <i className="fas fa-bullhorn"></i>
            </div>
            <h3>Online Advertising</h3>
            <p>
              Develop targeted advertising campaigns to maximize your visibility
              on platforms like Amazon and Walmart.
            </p>
          </div>
          <div className="grid-item">
            <div className="icon flexibility-icon">
              <i className="fas fa-sync-alt"></i>
            </div>
            <h3>Marketing Flexibility</h3>
            <p>
              Adapt strategies to changing consumer demands and market
              conditions.
            </p>
          </div>
          <div className="grid-item">
            <div className="icon seo-icon">
              <i className="fas fa-chart-line"></i>
            </div>
            <h3>SEO Website Strategy</h3>
            <p>
              Boost your website’s accessibility and visibility with proven SEO
              techniques.
            </p>
          </div>
          <div className="grid-item">
            <div className="icon advertising-strategy-icon">
              <i className="fas fa-lightbulb"></i>
            </div>
            <h3>Advertising Strategy</h3>
            <p>
              Implement creative advertising strategies to attract and retain
              your target audience.
            </p>
          </div>
        </div>
      </div>
       {/* Funnel Strategy Section */}
       <div className="strategyDevelopment-funnel">
        <h2>Webixgo Growth Formula Delivers the Most Effective Results</h2>
        <p>Drive Gains at Both Ends of Your Sales Funnel</p>

        <div className="funnel-stages">
          <div className="stage">
            <h3 className="primary-color">Attract</h3>
            <p>
              Attract your ideal client segments and drive awareness through
              targeted SEO, paid campaigns, and more.
            </p>
          </div>
          <div className="stage">
            <h3 className="primary-color">Convert</h3>
            <p>
              Capture leads and nurture prospects with engaging content and
              calls-to-action.
            </p>
          </div>
          <div className="stage">
            <h3 className="primary-color">Close</h3>
            <p>
              Transform qualified leads into paying customers with personalized
              marketing efforts.
            </p>
          </div>
          <div className="stage">
            <h3 className="primary-color">Delight</h3>
            <p>
              Provide outstanding brand solutions to ensure loyalty and
              long-term engagement.
            </p>
          </div>
        </div>

        <p className="measure-line">Measure → Strategize → Execute</p>
      </div>
    </div>
 
    </>
  );
};

export default StrategyDevelopment;
