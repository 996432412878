import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import Helmet

import Home from './Home/Home';
import ContactUs from './Component/ContactUs/ContactUs';
import AboutUs from './Component/AboutUs/AboutUs';
import BlogPage from './Component/Blog/Blog';
import StrategyDevelopment from './Pages/DigitalMarketing/StrategyDevelopment/StrategyDevelopment';
import FranchiseMarketing from './Pages/DigitalMarketing/FranchiseMarketing/FranchiseMarketing';
import EnterpriseMarketingPage from './Pages/DigitalMarketing/EnterpriseMarketing/EnterpriseMarketing';
import LocalSEO from './Pages/SEO/LocalSEO/LocalSEO';
import TechnicalSEO from './Pages/SEO/TechnicalSEO/TechnicalSEO';
import EnterpriseSEO from './Pages/SEO/EnterpriseSEO/EnterpriseSEO';
import FacebookMarketing from './Pages/SocialMedia/FacebookMarketing/FacebookMarketing';
import InstagramMarketing from './Pages/SocialMedia/InstagramMarketing/Instagram';
import LinkedInMarketing from './Pages/SocialMedia/LinkdinMarketing/LinkdinMarketing';
import CustomDesign from './Pages/WebDesign/CustomDesign/CustomDesign';
import WordPressBanner from './Pages/WebDesign/WordprerssDesign/WordpressDesign';
import DigitalMarketing from './Pages/DigitalMarketing/DigitalMarketing';
import Layout from './Component/Layout/Layout';
import ReactWebsite from './Pages/WebDesign/ReactWebsite/ReactWebsite';
import SocialMedia from './Pages/SocialMedia/SocialMedia';
import SEO from './Pages/SEO/SEO';
import WebDesign from './Pages/WebDesign/WebDesign';
import GoogleAds from './Pages/PPC-Advertising/Google-Ads/Googleads';
import LeadGenerationServices from './Pages/PPC-Advertising/LeadGenerationServices/LeadGenerationServices';
import FranchisePPCMarketing from './Pages/PPC-Advertising/Franchise-PPC-Marketing/Franchise-ppc-marketing';
import PPCAdvertising from './Pages/PPC-Advertising/PPCAdvertising';
import SEOcontentwriting from './Pages/ContentMarketing/SEOContentWriting/SEOcontentwriting';
import EmailMarketing from './Pages/ContentMarketing/EmailMarketing/EmailMarketing';
import Copywriteservices from './Pages/ContentMarketing/CopywritingServices/Copywriteservices';
import ContentMarketing from './Pages/ContentMarketing/ContentMarketing';
import Androiddevelopment from './Pages/AppDevelopment/AndroidDevelopment/Androiddevelopment';
import Iosdevelopment from './Pages/AppDevelopment/IosDevelopment/iosdevelopment';
import Reactnative from './Pages/AppDevelopment/ReactNative/Reactnative';
import Ecommercedesign from './Pages/E-commerce/Ecommerce_design/Ecommercedesign';
import Ecommerceppc from './Pages/E-commerce/Ecommerce_ppc/Ecommerceppc';
import Ecommerceseo from './Pages/E-commerce/Ecommerce_seo/Ecommerceseo';
import AppDevelopment from './Pages/AppDevelopment/AppDevelopment';
import Ecommerce from './Pages/E-commerce/E-commerce';
import PricePage from './Component/Price/Price';
import BlogsPage from './Component/Bloggs/BlogsPage';
import PrivacyPolicyAndTerms from './Pages/PrivacyPolicyandTerms/PrivacyPolicyandTerms';
import ScrollToTop from './Component/ScrollTop'; // Import ScrollToTop component

const App = () => {
  const routeData = [
    { path: '/', element: <Home />, title: 'Software Company in Amritsar', description: 'Webixgo, a leading digital marketing company in Amritsar, offers SEO, SMM, PPC, app development, e-commerce solutions, and content marketing to drive your business growth.' },
    { path: '/contact-us', element: <ContactUs />, title: 'Contact Webixgo for digital marketing:Digital Marketing in Amritsar', description: 'Contact Webixgo Team for SEO, social media, PPC, web design, and digital marketing in Amritsar, Punjab' },
    { path: '/about-us', element: <AboutUs />, title: 'Webixgo: Best digital marketing Company', description: 'Webixgo specializes in SEO, social media, PPC, and web design, helping businesses grow online with tailored strategies and measurable results.' },
    { path: '/portfolio', element: <BlogPage />, title: 'Software Company Portfolio in Amritsar', description: ' Innovative digital marketing, SEO, social media, and web design projects driving growth and transforming online presence:Webixgo Portfolio' },
    { path: '/digital-marketing', element: <DigitalMarketing />, title: 'Software Company  - Your Company', description: 'Explore our digital marketing services.' },
    { path: '/strategy-development', element: <StrategyDevelopment />, title: 'Strategy Development - Your Company', description: 'Learn about our strategy development services.' },
    { path: '/franchise-marketing', element: <FranchiseMarketing />, title: 'Franchise Marketing - Your Company', description: 'Boost your franchise with expert marketing strategies.' },
    { path: '/enterprise-marketing', element: <EnterpriseMarketingPage />, title: 'Enterprise Marketing - Your Company', description: 'Marketing services tailored for enterprises.' },
    { path: '/seo', element: <SEO />, title: 'SEO company in Amritsar,', description: 'Webixgo is a leading SEO company in Amritsar, delivering expert digital marketing solutions to boost your online visibility, drive traffic, and grow your business.' },
    { path: '/local-seo', element: <LocalSEO />, title: 'Local SEO - Your Company', description: 'Dominate local search results with our Local SEO services.' },
    { path: '/technical-seo', element: <TechnicalSEO />, title: 'Technical SEO - Your Company', description: 'Optimize the technical aspects of your website for better performance.' },
    { path: '/enterprise-seo', element: <EnterpriseSEO />, title: 'Enterprise SEO - Your Company', description: 'SEO services tailored for large-scale businesses.' },
    { path: '/social-media-marketing', element: <SocialMedia />, title: 'Social Media Marketing in  Amritsar', description: 'Webixgo offers professional social media marketing in Amritsar to elevate your brand. We create custom strategies that boost engagement, expand reach, and drive business growth.' },
    { path: '/facebook', element: <FacebookMarketing />, title: 'Facebook Marketing - Your Company', description: 'Reach your audience with expert Facebook marketing strategies.' },
    { path: '/instagram', element: <InstagramMarketing />, title: 'Instagram Marketing - Your Company', description: 'Enhance your presence on Instagram with our marketing services.' },
    { path: '/linkdin', element: <LinkedInMarketing />, title: 'LinkedIn Marketing - Your Company', description: 'Connect with professionals using LinkedIn marketing strategies.' },
    { path: '/web-design', element: <WebDesign />, title: 'web designing company in Amritsar', description: 'Webixgo is a leading web designing company in Amritsar, offering custom, user-friendly websites that help businesses grow online. Affordable, professional, and SEO-optimized services.' },
    { path: '/custom-design', element: <CustomDesign />, title: 'Custom Web Design - Your Company', description: 'Get a custom-designed website tailored to your business.' },
    { path: '/wordpress-design', element: <WordPressBanner />, title: 'WordPress Design - Your Company', description: 'Professional WordPress website design services.' },
    { path: '/react-development', element: <ReactWebsite />, title: 'React Development - Your Company', description: 'Build modern websites with React development services.' },
    { path: '/ppc-advertising', element: <PPCAdvertising />, title: 'PPC Advertising company in Amritsar', description: 'Webixgo is a top PPC advertising company in Amritsar, specializing in cost-effective paid search campaigns that deliver targeted traffic, improve ROI, and help your business grow online.' },
    { path: '/google-ads', element: <GoogleAds />, title: 'Google Ads - Your Company', description: 'Boost your business with Google Ads campaigns.' },
    { path: '/lead-generation-services', element: <LeadGenerationServices />, title: 'Lead Generation - Your Company', description: 'Generate quality leads for your business.' },
    { path: '/franchise-ppc-marketing', element: <FranchisePPCMarketing />, title: 'Franchise PPC Marketing - Your Company', description: 'Grow your franchise with PPC marketing strategies.' },
    { path: '/content-marketing', element: <ContentMarketing />, title: 'Content Marketing Company in  Amritsar', description: 'Webixgo is a top content marketing company in Amritsar, specializing in creating SEO-friendly, engaging content that boosts brand visibility and drives business growth.' },
    { path: '/seo-content-writing', element: <SEOcontentwriting />, title: 'SEO Content Writing - Your Company', description: 'Create SEO-optimized content for your website.' },
    { path: '/copy-write-services', element: <Copywriteservices />, title: 'Copywriting Services - Your Company', description: 'Professional copywriting services to drive results.' },
    { path: '/email-marketing', element: <EmailMarketing />, title: 'Email Marketing - Your Company', description: 'Reach your customers with targeted email campaigns.' },
    { path: '/app-development', element: <AppDevelopment />, title: 'App development company in  Amritsar', description: 'Webixgo is a top app development company in Amritsar, specializing in creating innovative mobile and web apps that drive business growth and enhance user experiences.' },
    { path: '/android-development', element: <Androiddevelopment />, title: 'Android Development - Your Company', description: 'Create cutting-edge Android applications.' },
    { path: '/ios-development', element: <Iosdevelopment />, title: 'iOS Development - Your Company', description: 'Develop feature-rich iOS applications.' },
    { path: '/react-native', element: <Reactnative />, title: 'React Native Development - Your Company', description: 'Cross-platform app development with React Native.' },
    { path: '/e-commerce', element: <Ecommerce />, title: 'E-commerce company in Amritsar', description: 'Webixgo, an innovative e-commerce company in Amritsar, specializes in transforming businesses with tailored digital marketing strategies to drive growth and maximize online presence.' },
    { path: '/ecommerce_design', element: <Ecommercedesign />, title: 'E-commerce Design - Your Company', description: 'Design professional e-commerce websites.' },
    { path: '/ecommerce_ppc', element: <Ecommerceppc />, title: 'E-commerce PPC - Your Company', description: 'Drive sales with e-commerce PPC campaigns.' },
    { path: '/ecommerce_seo', element: <Ecommerceseo />, title: 'E-commerce SEO - Your Company', description: 'Improve your store\'s visibility with e-commerce SEO.' },
    { path: '/price', element: <PricePage />, title: 'Pricing - Your Company', description: 'View our pricing plans and packages.' },
    { path: '/blog', element: <BlogsPage />, title: 'Blog - Your Company', description: 'Read our latest blog posts and updates.' },
    { path: '/privacy-policy', element: <PrivacyPolicyAndTerms />, title: 'Privacy Policy & Terms - Webixgo', description: 'View our privacy policy and terms & conditions.' },
  ];

  return (
    <Router>
        <ScrollToTop/>
      <Layout>
        <Routes>
          {routeData.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <>
                  <Helmet>
                    <title>{route.title}</title>
                    <meta name="description" content={route.description} />
                  </Helmet>
                  {route.element}
                </>
              }
            />
          ))}
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;