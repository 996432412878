import React from "react";
import { useNavigate } from "react-router-dom";
import "./Abouthome.css";
import { FaSmile, FaClock, FaUsers } from "react-icons/fa";


const Abouthome = () => {
  const handleButtonClick = () => {
    window.location.href = "https://webixgo.com/about-us";
  };

  return (
    <>

      <div className="abouthome-container">
        {/* Heading Section */}
        <div className="abouthome-header">
          <h1 className="abouthome-main-heading">Discover Our Commitment</h1>
          <p className="abouthome-header-description">
            At Webixgo, we believe in creating opportunities that foster growth, innovation, and excellence. Learn more about what drives us!
          </p>
        </div>

        {/* Content Section */}
        <div className="abouthome-content">
          <div className="abouthome-left">
            <div className="abouthome-card abouthome-card-1">
              <div className="abouthome-card-icon">
                <FaSmile className="abouthome-icon" style={{ color: "#3681AB" }} />
              </div>
              <p className="abouthome-card-title">Positive Work Culture</p>
            </div>
            <div className="abouthome-card abouthome-card-2">
              <div className="abouthome-card-icon">
                <FaClock className="abouthome-icon" style={{ color: "#4b5154" }} />
              </div>
              <p className="abouthome-card-title">Long-Term Career Paths</p>
            </div>
            <div className="abouthome-card abouthome-card-3">
              <div className="abouthome-card-icon">
                <FaUsers className="abouthome-icon" style={{ color: "#999BBE" }} />
              </div>
              <p className="abouthome-card-title">People-Oriented Leadership</p>
            </div>
          </div>

          <div className="abouthome-right">
            <div className="abouthome-textbox">
              <h2 className="abouthome-heading">Why Webixgo?</h2>
              <p className="abouthome-subheading">Driving Individual and Business Transformations Since 2021</p>
              <p className="abouthome-description">
                Webixgo is a leading Digital Marketing Company in Amritsar with over 4 years of experience in transforming businesses through innovative digital strategies. As a trusted Social Media Company in Amritsar, SEO Company in Amritsar, and Web Designing Company in Amritsar, we help businesses boost their online presence and achieve measurable growth. Our expertise extends to Graphic Designing, Web Development, and App Development services, ensuring tailored solutions that meet your unique needs. Join us at Webixgo and let us drive your digital transformation and success.
              </p>
              <p className="abouthome-description">
                If you’re passionate about doing your best work and helping others, we’d love to meet you.
              </p>
              <button className="abouthome-button" onClick={handleButtonClick}>
                LEARN MORE ABOUT US
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Abouthome;
