import React, { useState } from 'react';
import './Faqsection.css'; // Ensure you have the styles defined here.
import img from "../../Assest/help.webp"

const FAQ = () => {
  const [openQuestion, setOpenQuestion] = useState(null);

  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const faqs = [
    {
      question: 'What is Webixgo?',
      answer: 'Webixgo is a company that specializes in providing innovative web solutions tailored to your needs.',
    },
    {
      question: 'How can I contact Webixgo?',
      answer: 'You can contact us via email at contact@webixgo.com or call us at +1234567890.',
    },
    {
      question: 'What services does Webixgo offer?',
      answer: 'Webixgo offers web development, mobile application development, and digital marketing services.',
    },
    {
      question: 'Does Webixgo provide customer support?',
      answer: 'Yes, we provide 24/7 customer support to ensure your needs are always met.',
    },
  ];

  return (
    <div className="faq-page">
      <div className="faq-content">
        <div className="faq-title"><strong>Frequently Asked Questions</strong></div>
        {faqs.map((faq, index) => (
          <div className="faq-item" key={index}>
            <div
              className={`faq-question ${openQuestion === index ? 'open' : ''}`}
              onClick={() => toggleQuestion(index)}
            >
              <span>{faq.question}</span>
              <span className="faq-arrow">{openQuestion === index ? '▲' : '▼'}</span>
            </div>
            {openQuestion === index && (
              <div className="faq-answer">{faq.answer}</div>
            )}
          </div>
        ))}
      </div>
      <div className="faq-image">
        <img src={img} alt="FAQ Illustration" className="home-image" />
      </div>
    </div>
  );
};

export default FAQ;