import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./PrivacyPolicyandTerms.css";

const PrivacyPolicyAndTerms = () => {
  const location = useLocation();

  useEffect(() => {
    const sectionId = location.hash.substring(1); // Extract ID from hash
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <div className="policy-container">
      <section id="privacy-policy" className="policy-section">
        <h1>Privacy Policy</h1>
        <p>
          At Webixgo, we are dedicated to safeguarding your personal information and ensuring your experience with
          our services—web development, app development, SEO, and other solutions—is both secure and satisfying.
        </p>
        <h2>Information We Collect</h2>
        <ul>
          <li>
            **Personal Information:** Name, email address, phone number, and company details shared through contact
            forms or inquiries.
          </li>
          <li>
            **Technical Data:** IP address, browser type, device details, and usage patterns on our website.
          </li>
          <li>
            **Project Details:** Requirements and specifications shared for development and design projects.
          </li>
        </ul>
        <h2>How We Use Your Information</h2>
        <p>
          Your data enables us to deliver personalized services, respond to your inquiries, and improve our
          offerings. We may also use your information for:
        </p>
        <ul>
          <li>Customizing solutions for your business needs.</li>
          <li>Providing updates on project progress and timelines.</li>
          <li>
            Communicating marketing updates, offers, and new service launches (with your consent).
          </li>
        </ul>
        <h2>Data Security</h2>
        <p>
          Webixgo implements industry-standard security measures to protect your data. However, no online
          communication is completely secure, and we encourage responsible sharing of sensitive information.
        </p>
      </section>

      <section id="terms-and-conditions" className="policy-section">
        <h1>Terms & Conditions</h1>
        <p>
          These terms govern the use of Webixgo’s services and website. By accessing our platform, you agree to
          comply with these conditions.
        </p>
        <h2>Our Services</h2>
        <p>
          Webixgo specializes in delivering tailored software solutions, including but not limited to:
        </p>
        <ul>
          <li>Custom web development and design.</li>
          <li>Mobile app development for Android and iOS platforms.</li>
          <li>Search engine optimization (SEO) and digital marketing.</li>
          <li>E-commerce solutions and API integration.</li>
        </ul>
        <h2>Client Responsibilities</h2>
        <ul>
          <li>Providing accurate project requirements and information.</li>
          <li>Adhering to project timelines and approvals for smoother execution.</li>
          <li>Ensuring content shared for projects does not infringe copyright or intellectual property rights.</li>
        </ul>
        <h2>Payment Terms</h2>
        <p>
          Payments for services are subject to agreed milestones and timelines. Invoices must be cleared within the
          specified time to avoid service delays or suspensions.
        </p>
        <h2>Prohibited Uses</h2>
        <ul>
          <li>Reselling or redistributing our solutions without prior consent.</li>
          <li>Engaging in activities that disrupt the functionality of our platform or services.</li>
          <li>Uploading malicious or unauthorized content to our systems.</li>
        </ul>
        <h2>Limitation of Liability</h2>
        <p>
          Webixgo is not liable for any direct or indirect damages caused due to the use of our services, delays, or
          technical issues beyond our control.
        </p>
        <h2>Amendments</h2>
        <p>
          We reserve the right to update these terms and policies to align with industry standards and legal
          regulations. Users are encouraged to review them periodically.
        </p>
        <p>
          For queries or additional details, please <a href="/contact-us">contact us</a>.
        </p>
      </section>
    </div>
  );
};

export default PrivacyPolicyAndTerms;
