import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import "./Price.css";

const PricePage = () => {
  const services = [
    { id: "digital-marketing", title: "Digital Marketing" },
    { id: "web-development", title: "Web Development" },
    { id: "seo", title: "SEO" },
    { id: "app-development", title: "App Development" },
    { id: "social-media-marketing", title: "Social Media Marketing" },
    { id: "ppc-advertising", title: "PPC Advertising" },
    { id: "content-marketing", title: "Content Marketing" },
    { id: "e-commerce", title: "E-Commerce Development" },
  ];

  const plans = {
    "digital-marketing": [
      {
        title: "Basic",
        features: [
          "Social Media Account Setup",
          "8 Social Media Creatives",
          "4 Reels Creation",
          "1 Shoot",
          "Boost Ads",
          "20 Stories",
          "No Ads Campaign (Meta Only)",
          "Static (Motion)",
        ],
        color: "#4682B4",
      },
      {
        title: "Standard",
        features: [
          "Social Media Account Setup",
          "16 Social Media Creatives",
          "8 Reels Creation",
          "2 Shoots",
          "Boost Ads",
          "40 Stories",
          "Ads Campaign (Meta Only)",
          "Static (Motion)",
        ],
        color: "#4682B4",
      },
      {
        title: "Premium",
        features: [
          "Social Media Account Setup",
          "32 Social Media Creatives",
          "16 Reels Creation",
          "4 Shoots",
          "Boost Ads",
          "60 Stories",
          "Ads Campaign (Meta Only)",
          "Static (Motion)",
        ],
        color: "#4682B4",
      },
    ],
    "web-development": [
      {
        title: "4682B4",
        features: [
          "Hosting",
          "Domain",
          "SSL Certificate",
          "Development (6 Pages)",
          "Maintenance (1 Month)",
          "Logo",
        ],
        color: "#4682B4",
      },
      {
        title: "Standard",
        features: [
          "Hosting",
          "Domain",
          "SSL Certificate",
          "Development (15 Pages)",
          "Maintenance (2 Months)",
          "Logo",
        ],
        color: "#4682B4",
      },
      {
        title: "Premium",
        features: [
          "Hosting",
          "Domain",
          "SSL Certificate",
          "Unlimited Pages",
          "Maintenance (3 Months)",
          "Logo",
        ],
        color: "#4682B4",
      },
    ],
    "seo": [
      {
        title: "Basic",
        features: [
          "On-Page SEO",
          "OFF-Page SEO (50 Backlinks)",
          "Keyword Research",
          "Technical SEO",
          "Performance Reports (Monthly)",
        ],
        color: "#4682B4",
      },
      {
        title: "Standard",
        features: [
          "On-Page SEO",
          "OFF-Page SEO (100 Backlinks)",
          "Keyword Research",
          "Technical SEO",
          "Performance Reports (15 Days)",
        ],
        color: "#4682B4",
      },
      {
        title: "Premium",
        features: [
          "On-Page SEO",
          "OFF-Page SEO (Unlimited Backlinks)",
          "Keyword Research",
          "Technical SEO",
          "Performance Reports (Weekly)",
        ],
        color: "#4682B4",
      },
    ],
    "app-development": [
      {
        title: "Basic",
        features: [
          "Playstore Subscription",
          "6 Screens",
          "1 Month Maintenance",
          "SSL Certificate",
          "Logo",
        ],
        color: "#4682B4",
      },
      {
        title: "Standard",
        features: [
          "Playstore Subscription",
          "15 Screens",
          "2 Months Maintenance",
          "SSL Certificate",
          "Logo",
        ],
        color: "#4682B4",
      },
      {
        title: "4682B4",
        features: [
          "Playstore Subscription",
          "Unlimited Screens",
          "3 Months Maintenance",
          "SSL Certificate",
          "Logo",
        ],
        color: "#4682B4",
      },
    ],
    "social-media-marketing": [
      {
        title: "Basic",
        features: [
          "1 Social Media Account",
          "Basic Engagement",
          "Monthly Analysis",
        ],
        color: "#4682B4",
      },
      {
        title: "Standard",
        features: [
          "2 Social Media Accounts",
          "Content Creation",
          "Weekly Analysis",
        ],
        color: "#4682B4",
      },
      {
        title: "Premium",
        features: [
          "All Social Media Accounts",
          "Advanced Campaigns",
          "Daily Analysis",
        ],
        color: "#4682B4",
      },
    ],
    "ppc-advertising": [
      {
        title: "Basic",
        features: [
          "1 Ad Campaign",
          "Basic Optimization",
          "Monthly Reporting",
        ],
        color: "#4682B4",
      },
      {
        title: "Standard",
        features: [
          "3 Ad Campaigns",
          "Keyword Research",
          "Weekly Reporting",
        ],
        color: "#4682B4",
      },
      {
        title: "Premium",
        features: [
          "Unlimited Campaigns",
          "Advanced Optimization",
          "Daily Reporting",
        ],
        color: "#4682B4",
      },
    ],
    "content-marketing": [
      {
        title: "Basic",
        features: [
          "1 Blog Post",
          "Keyword Optimization",
          "Monthly Reporting",
        ],
        color: "#4682B4",
      },
      {
        title: "Standard",
        features: [
          "4 Blog Posts",
          "SEO Optimization",
          "Weekly Reporting",
        ],
        color: "#4682B4",
      },
      {
        title: "Premium",
        features: [
          "Unlimited Blog Posts",
          "Custom Content Strategy",
          "Daily Reporting",
        ],
        color: "#4682B4",
      },
    ],
    "e-commerce": [
      {
        title: "Basic",
        features: [
          "E-Commerce Website Setup",
          "Product Upload (Up to 50)",
          "Basic SEO Optimization",
          "Payment Gateway Integration",
          "1 Month Support",
          " Marketing Strategy",
        ],
        color: "#4682B4",
      },
      {
        title: "Standard",
        features: [
          "E-Commerce Website Setup",
          "Product Upload (Up to 200)",
          "Advanced SEO Optimization",
          "Payment Gateway Integration",
          "3 Months Support",
          "Basic Marketing Strategy",
        ],
        color: "#4682B4",
      },
      {
        title: "Premium",
        features: [
          "E-Commerce Website Setup",
          "Unlimited Product Upload",
          "Full SEO Optimization",
          "Payment Gateway Integration",
          "6 Months Support",
          "Advanced Marketing Strategy",

        ],
        color: "#4682B4",
      },
    ],
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="price-page">
      <div className="price-services-menu">
        <h1 className="price-services-title">Our Services</h1>
        <ul className="price-services-list">
          {services.map((service) => (
            <li
              key={service.id}
              className="price-service-item"
              onClick={() => scrollToSection(service.id)}
            >
              {service.title}
            </li>
          ))}
        </ul>
      </div>

      {services.map((service) => (
        <div key={service.id} id={service.id} className="price-service-section">
          <h2 className="price-section-title">{service.title}</h2>
          <div className="price-plans">
            {plans[service.id]?.map((plan, index) => (
              <PlanCard key={index} plan={plan} />
            ))}
          </div>
        </div>
      ))}

<button className="scroll-to-top" onClick={scrollToTop}>
  <FontAwesomeIcon icon={faArrowUp} />
</button>

    </div>
  );
};

const PlanCard = ({ plan }) => {
  const navigate = useNavigate();

  const handleChoosePlan = () => {
    navigate("/contact-us");
  };

  return (
    <div className="price-card" style={{ borderColor: plan.color }}>
      <div className="price-triangle" style={{ borderBottomColor: plan.color }}>
        <p className="price-triangle-text">{plan.title}</p>
      </div>
      <ul className="plan-features">
        {plan.features.map((feature, idx) => (
          <li key={idx} className="plan-feature">
            <FontAwesomeIcon icon={faCheckCircle} className="feature-icon" />
            {feature}
          </li>
        ))}
      </ul>
      <div className="button-container">
        <button className="choose-plan-button" onClick={handleChoosePlan}>
          Choose Plan
        </button>
      </div>
    </div>
  );
};

export default PricePage;
